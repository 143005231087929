//Hooks
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Component
import Form from "./Form";

//CSS
import classes from "./ContactUs.module.css";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <section className="containerStyle">
      <div className={classes.container}>
        <div className={classes.info}>
          <p>
            {t("forms.find_answers")}&nbsp;
            <Link to="faq" className="list">
              {t("forms.faq")}
            </Link>
            &nbsp;{t("forms.page")}
          </p>
          <a href="tel:070359589">{t("other.phone")}: 070 359 589</a>
          <a href="mailto:greg.gego@gmail.com">{t("other.email")}: greg.gego@gmail.com</a>
        </div>
        <div className={classes.form}>
          <span className={classes.requiredField}>
            <p>
              {t("forms.user_question_info")}
            </p>
            <br />
            <br />
            <span>{t("forms.required")}</span>
          </span>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
