//Hooks
import { motion as m, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import usePagination from "../../../hooks/use-pagination";
import useWindowSize from "../../../hooks/use-windowDimensions";

//Components
import ProductItem from "./ProductItem/ProductItem";
import PaginationButtons from "../PaginationButtons";
import Sort from "../Sort";

//CSS
import classes from "./AllProductCategories.module.css";

//Data
import { products } from "../../../data/products/all-products/all-products";

const AllTheProducts = () => {
  const size = useWindowSize()
      
  const allProducts = products
    ? products.flatMap((product) => product.subMenu)
    : [];

  const [activeCategory, setActiveCategory] = useState(allProducts);
  const [defaultData, setDefaultData] = useState(allProducts);

  const activeProductCategory = useSelector(
    (state) => state.activeProductCategory.id
  );

  useEffect(() => {
    if (activeProductCategory == 0 || !products) {
      setActiveCategory(allProducts);
      setDefaultData(allProducts);
    } else {
      const selectProductCategory = products
        .filter((product) => product.id == activeProductCategory)
        .flatMap((subMenu) => subMenu.subMenu);
      if (selectProductCategory) {
        setActiveCategory(selectProductCategory);
        setDefaultData(selectProductCategory)
      }
    }
  }, [activeProductCategory]);

  const liStyle = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0, transition: {duration: 0} },
    transition: { duration: 0.3, delay: 0 },
  };

  const itemsPerPage = 9; // Number of items per page
  const totalProducts = activeCategory.length;
  const scrollToTopOffset = size.width < 640 ? 0 : 0;

  const {
    currentPage,
    nextPage,
    prevPage,
    totalPages,
    startIndex,
    endIndex,
    setCurrentPage,
  } = usePagination(totalProducts, itemsPerPage, activeProductCategory, scrollToTopOffset);

  return (
    <>
      <Sort
        setData={setActiveCategory}
        data={activeCategory}
        defaultData={defaultData}
      />
      <div className={`${classes.container} scroll-to-top`}>
        <div className={classes.productContainer}>
          <ul className={classes.gridContainer}>
            <AnimatePresence>
              {activeCategory.slice(startIndex, endIndex).map((prod, id) => (
                <m.li {...liStyle} key={id}>
                  <ProductItem
                    prod={prod}
                    currentPage={currentPage}
                    activeProductCategory={activeProductCategory}
                  />
                </m.li>
              ))}
            </AnimatePresence>
          </ul>
          <PaginationButtons
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            totalPages={totalPages}
            totalProducts={totalProducts}
            setCurrentPage={setCurrentPage}
            top={scrollToTopOffset}
          />
        </div>
      </div>
    </>
  );
};

export default AllTheProducts;
