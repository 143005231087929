//Hooks
import { useRef } from "react";
import { motion as m } from "framer-motion";
import Lottie from "lottie-react";
import useImageLoader from "../../../../hooks/use-imageLoader";

//IconAnimation
import loading from "../../../../assets/anim/Animation - loading.json";

//CSS
import classes from "./ImageContainer.module.css";

const ImageContainer = ({
  src,
  alt,
  hasTag,
  tagColor,
  tagTitle,
  isVisible,
  initialScale,
  animateScale,
  className
}) => {
  const loadingRef = useRef(null);
  const imageLoaded = useImageLoader(src);

  return (
    <div className={`${classes.imgContainer} ${className}`}>
      {imageLoaded ? (
        <m.img
          initial={{ scale: initialScale }}
          animate={{ scale: isVisible ? animateScale : initialScale }}
          transition={{ duration: 0.3 }}
          src={src}
          alt={alt}
        />
      ) : (
        <Lottie
          lottieRef={loadingRef}
          onComplete={() => {
            loadingRef.current?.goToAndStop(72, imageLoaded);
          }}
          animationData={loading}
        />
      )}
      {/* {hasTag && imageLoaded && (
        <span className={classes.tag}>
          <p style={{ color: tagColor }}>{tagTitle}</p>
        </span>
      )} */}
    </div>
  );
};

export default ImageContainer;
