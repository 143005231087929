//Hooks
import { useRef } from "react";
import { motion as m, useInView } from "framer-motion";
import useWindowDimensions from "../../../hooks/use-windowDimensions";
import { useTranslation } from "react-i18next";

//Components
import Button from "../../ui/Button";

//Animations
import { PresentationAnimation } from "../../../util/animationsVariants";

//Img
import chocolate from "../../../assets/images/other/chocolate2.jpg";

//CSS
import classes from "./Presentation.module.css";

const Presentation = ({ title, paragraph, callToAction }) => {
  const { t } = useTranslation();
  const { learn_more } = t("buttons");

  const size = useWindowDimensions();
  const animationStartRef = useRef(null);
  const titleRef = useRef(null);
  const isInViewContainer = useInView(animationStartRef, { once: true });

  const animations = PresentationAnimation({ isInViewContainer });

  const photoContainer = (
    <div className={classes.container__photo}>
      <m.span {...animations.photo}></m.span>
      <img src={chocolate} alt="discover our chocolate" />
    </div>
  );

  return (
    <section className={classes.container}>
      <m.div ref={titleRef} className={classes.container__text}>
        <div>
          <m.h1 {...animations.text}>{title}</m.h1>
        </div>
        {size.width < 900 && photoContainer}
        <div>
          <m.p {...animations.text}>{paragraph}</m.p>
        </div>
        <Button buttonName={learn_more} callToAction={callToAction} />
      </m.div>
      {size.width > 900 && photoContainer}
      <section ref={animationStartRef}></section>
    </section>
  );
};

export default Presentation;
