import { CoconutCaramelCupcake } from "../cupcakes/coconut-caramel-cupcake";
import { TangerineAlmondCupcake } from "../cupcakes/tangerine-almond-cupcake";
import { WhiteChocolateMintCupcake } from "../cupcakes/white-chocolate-mint-cupcake";

export const CupcakeOfTheMonth = [
  //JANUARY --------------------------------------------------------------------------------------------------------------
  {
    name: "January",
    id: "january",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //FEBRUARY--------------------------------------------------------------------------------------------------------------
  {
    name: "February",
    id: "february",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //MARCH--------------------------------------------------------------------------------------------------------------
  {
    name: "March",
    id: "march",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //APRIL--------------------------------------------------------------------------------------------------------------
  {
    name: "April",
    id: "april",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //MAY--------------------------------------------------------------------------------------------------------------
  {
    name: "May",
    id: "may",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //JUNE--------------------------------------------------------------------------------------------------------------
  {
    name: "June",
    id: "june",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //JULY--------------------------------------------------------------------------------------------------------------
  {
    name: "July",
    id: "july",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //AUGUST--------------------------------------------------------------------------------------------------------------
  {
    name: "August",
    id: "august",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //SEPTEMBER--------------------------------------------------------------------------------------------------------------
  {
    name: "September",
    id: "september",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //OCTOBER--------------------------------------------------------------------------------------------------------------
  {
    name: "October",
    id: "october",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //NOVEMBER--------------------------------------------------------------------------------------------------------------
  {
    name: "November",
    id: "november",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
  //DECEMBER--------------------------------------------------------------------------------------------------------------
  {
    name: "December",
    id: "december",
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
];
