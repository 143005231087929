//Hooks
import { useSelector } from "react-redux";
import { AnimatePresence, motion as m } from "framer-motion";

//Components
import Paths from "../../layout/header/Paths";
import SearchIconButton from "../SearchIconButton";

//CSS
import classes from "./RecentlySearchedDesktop.module.css";

const RecentlySearchedDesktop = () => {
  const suggestions = useSelector((state) => state.suggestions.suggestions);
  const searchInput = useSelector((state) => state.input.value);

  const isDesktopSearchActive = useSelector(
    (state) => state.isSearchInputActive.desktop
  );

  const recentAnime = {
    initial: { width: "100%", opacity: 0 },
    animate: {
      width: "100%",
      opacity: 1,
      transition: {
        top: { delay: 0, duration: 0.4 },
        width: { delay: 0.1, duration: 0.4 },
        height: { delay: 0, duration: 0 },
        opacity: { delay: 0.2 },
      },
    },
    exit: {
      width: "100%",
      height: 0,
      transition: {
        top: { delay: 0.2, duration: 0.4 },
        height: { delay: 0, duration: 0.4 },
      },
    },
  };

  const suggestionsStyles = {
    initial: { height: 0 },
    animate: { height: "auto" },
    exit: { height: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  return (
    <m.div {...recentAnime} className={classes.recent}>
      <AnimatePresence>
        {suggestions.length > 0 && searchInput.length > 0 && (
          <m.ul {...suggestionsStyles} className={classes.ul}>
            <Paths
              arr={suggestions}
              isSearchClicked={isDesktopSearchActive}
              children={
                <SearchIconButton />
              }
              delayAnimation={0}
            />
          </m.ul>
        )}
      </AnimatePresence>
    </m.div>
  );
};

export default RecentlySearchedDesktop;
