//Hooks
import { IconContext } from "react-icons";
import useWindowSize from "../../hooks/use-windowDimensions"
//Icons
import { CiSearch } from "react-icons/ci";

const SearchIconButton = ({ className, onClick, isDesktopSearchActive }) => {
  const size = useWindowSize()
  const searchButtonStyles = {
    width: !isDesktopSearchActive && size.width > 1100 ? "100%" : null,
  };

  const searchIconStyles = {
    fontSize: "1.8rem",
    marginLeft: "5px",
    opacity: "0.4"
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <span
        style={{ ...searchButtonStyles }}
        className={className}
        onClick={onClick}
      >
        <CiSearch
          style={{
            ...searchIconStyles,
          }}
        />
      </span>
    </IconContext.Provider>
  );
};

export default SearchIconButton;
