//Hooks
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { useInput } from "../../../hooks/use-input.js";
import { useDispatch } from "react-redux";
import {
  isNotification,
  notificationMessage,
  notificationColor,
  notificationIcon,
} from "../../../store/index.js";

//Utilities
import { isEmail, isNotEmpty } from "../../../util/validation.js";

//IconAnimations
import messageAnimation from "../../../assets/anim/Animation - loading.json";

//Component
import Button from "../../ui/Button.js";
import Input from "../../ui/Input.js";
import IconAnimation from "../../ui/iconAnimations/IconAnimation.js";

//CSS
import classes from "./Form.module.css";

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const capRef = useRef(null);
  const [capValue, setCapValue] = useState(null);

  const {
    value: nameValue,
    handleInputChange: handleNameChange,
    handleInputBlur: handleNameBlur,
    hasError: nameHasError,
    valueHasError: nameValueHasError,
    setValue: setNameValue,
    setEdit: setNameEdit,
  } = useInput("", (value) => isNotEmpty(value));

  const {
    value: surnameValue,
    handleInputChange: handleSurnameChange,
    handleInputBlur: handleSurnameBlur,
    hasError: sirNameHasError,
    valueHasError: sirNameValueHasError,
    setValue: setSurnameValue,
    setEdit: setSurnameEdit,
  } = useInput("", (value) => isNotEmpty(value));

  const {
    value: emailValue,
    handleInputChange: handleEmailChange,
    handleInputBlur: handleEmailBlur,
    hasError: emailHasError,
    valueHasError: emailValueHasError,
    setValue: setEmailValue,
    setEdit: setEmailEdit,
  } = useInput("", (value) => isEmail(value) && isNotEmpty(value));

  const {
    value: subjectValue,
    handleInputChange: handleSubjectChange,
    handleInputBlur: handleSubjectBlur,
    hasError: subjectHasError,
    valueHasError: subjectValueHasError,
    setValue: setSubjectValue,
    setEdit: setSubjectEdit,
  } = useInput("", (value) => isNotEmpty(value));

  const {
    value: messageValue,
    handleInputChange: handleMessageChange,
    handleInputBlur: handleMessageBlur,
    hasError: messageHasError,
    valueHasError: messageValueHasError,
    setValue: setMessageValue,
    setEdit: setMessageEdit,
  } = useInput("", (value) => isNotEmpty(value));

  const isDisabled =
    !nameValueHasError ||
    !sirNameValueHasError ||
    !emailValueHasError ||
    !subjectValueHasError ||
    !messageValueHasError ||
    !capValue;

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (isDisabled) {
      dispatch(isNotification(true));
      dispatch(
        notificationMessage(t("notifications.incomplete user information"))
      );
      dispatch(notificationColor("red"));
      dispatch(notificationIcon("error"));
      setTimeout(() => {
        dispatch(isNotification(false));
      }, 5000);
      setIsLoading(false);
      return;
    }

    emailjs
      .sendForm(
        "service_78xzy02",
        "template_htaf49f",
        formRef.current,
        "_qKrXEZhNIK_Gl97P"
      )
      .then(
        () => {
          dispatch(isNotification(true));
          dispatch(notificationMessage(t("notifications.message sent")));
          dispatch(notificationColor("green"));
          dispatch(notificationIcon("success"));
          setIsLoading(false);
        },
        () => {
          dispatch(isNotification(true));
          dispatch(
            notificationMessage(t("notifications.message not delivered"))
          );
          dispatch(notificationColor("red"));
          dispatch(notificationIcon("error"));
          setIsLoading(false);
        }
      );

    setNameValue("");
    setNameEdit(false);

    setSurnameValue("");
    setSurnameEdit(false);

    setEmailValue("");
    setEmailEdit(false);

    setMessageValue("");
    setMessageEdit(false);

    setSubjectValue("");
    setSubjectEdit(false);

    e.target.reset();
    capRef.current.reset();

    const timer = setTimeout(() => {
      dispatch(isNotification(false));
    }, 5000);
    return () => clearTimeout(timer);
  };

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <form
      ref={formRef}
      onSubmit={sendEmail}
      className={classes.form}
      autoComplete="on"
    >
      <span className={classes.duo}>
        <Input
          className=""
          label={t("forms.first_name")}
          id="name"
          name="name"
          type="text"
          onBlur={handleNameBlur}
          onChange={handleNameChange}
          value={nameValue}
          error={nameHasError && t("forms.error_name")}
        />
        <Input
          className=""
          label={t("forms.last_name")}
          id="surname"
          name="surname"
          type="text"
          onBlur={handleSurnameBlur}
          onChange={handleSurnameChange}
          value={surnameValue}
          error={sirNameHasError && t("forms.error_surname")}
        />
      </span>
      <span className={classes.duo}>
        <Input
          className=""
          label={t("forms.email")}
          id="email"
          name="email"
          type="email"
          onBlur={handleEmailBlur}
          onChange={handleEmailChange}
          value={emailValue}
          error={emailHasError && t("forms.error_email")}
        />
        <Input
          className={classes.phone}
          label={t("forms.phone")}
          id="phone"
          name="phone"
          type="text"
        />
      </span>
      <Input
        className={classes.subject}
        label={t("forms.subject")}
        id="subject"
        name="subject"
        type="text"
        onBlur={handleSubjectBlur}
        onChange={handleSubjectChange}
        value={subjectValue}
        error={subjectHasError && t("forms.error_subject")}
      />
      <span className={classes.message}>
        <label>{t("forms.message")}</label>
        <div className={classes.textArea}>
          <textarea
            name="message"
            onBlur={handleMessageBlur}
            onChange={handleMessageChange}
            value={messageValue}
          />
          <div className={classes.controlErrorMessage}>
            {messageHasError && t("forms.error_message")}
          </div>
        </div>
        <span>
          <ReCAPTCHA
            sitekey={siteKey}
            onChange={(val) => setCapValue(val)}
            ref={capRef}
          />
        </span>
        <Button
          buttonName={t("buttons.submit")}
          value={"Send"}
          type={"submit"}
          disable={isLoading}
          triggerAnimation={isLoading}
        >
          <IconAnimation>
            <Lottie animationData={messageAnimation} />
          </IconAnimation>
        </Button>
      </span>
    </form>
  );
};

export default Form;
