//Hooks
import Swiper from "../../../../hooks/use-swiper";
import useWindowSize from "../../../../hooks/use-windowDimensions";
import { SwiperSlide } from "swiper/react";

//Component
import ImageWrapper from "./BannerImageWrapper";
import HeroSloganContainer from "./BannerInfo";

//CSS
import classes from "./BannerSwiper.module.css";

//Data
import { heroData } from "../../../../data/swipersData";

const BannerSwiper = () => {
  const size = useWindowSize();

  const handleResize = (swiperCore) => {
    swiperCore.autoplay.start();
    swiperCore.autoplay.resume();
  };

  return (
    <Swiper
      spaceBetween={0}
      pagination={{ dynamicBullets: heroData.length > 3 ? true : false }}
      scrollbar={false}
      loop={true}
      slidesPerView={1}
      parallax={true}
      speed={size.width > 640 ? 1000 : 700}
      autoplay={{
        delay: 7000,
        // pauseOnMouseEnter: true,
        disableOnInteraction: true,
      }}
      onResize={handleResize}
      swiperClass={classes.bannerSwiper}
      arrowClass={classes.bannerArrows}
    >
      {heroData.map(({ photo, alt, name, description }, i) => (
        <SwiperSlide key={i} className={classes.swiperSlides}>
          <ImageWrapper src={photo} alt={alt} photoClass={classes.imgWrapper} />
          <HeroSloganContainer name={name} description={description} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerSwiper;
