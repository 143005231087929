//Hooks
import MetaTags from "../../hooks/use-metaTags";

//Components
import ElementWrapper from "../layout/main/ElementWrapper";
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import FrequentlyAskedQuestions from "../layout/main/FrequentlyAskedQuestions";
import Guarantee from "../layout/main/Guarantee";
import BackgroundSweets from "../layout/main/BackgroundSweets";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import { useTranslation } from "react-i18next";

//Data
import { faqTags } from "../../data/metaTags/fagTags";

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTags tags={faqTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide />
        <BackgroundSweets pageName={t("pages.frequently_asked_questions")} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="faq">
        <FrequentlyAskedQuestions />
        <Guarantee />
      </ElementWrapper>
    </>
  );
};

export default FAQ;
