//Hooks
import { motion as m } from "framer-motion";

//CSS
import classes from "./IconAnimation.module.css";

const checkAnimationStyle = {
  initial: { top: "100%", left: "50%", transform: "translate(-50%, 0%)" },
  animate: { top: "50%", left: "50%", transform: "translate(-50%, -50%)" },
  exit: { top: "100%", left: "50%", transform: "translate(-50%, 0%)" },
  transition: { duration: 0.3, delay: 0 },
};

const IconAnimation = ({ children, name }) => {
  return (
    <m.span {...checkAnimationStyle} className={classes.container} key="2">
      {children}
      <span>{name}</span>
    </m.span>
  );
};

export default IconAnimation;
