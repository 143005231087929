//Hooks
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion as m } from "framer-motion";

//Components
import Button from "../../../ui/Button";

//CSS
import classes from "./BannerInfo.module.css";

const HeroSloganContainer = ({ description, name }) => {
  const { t } = useTranslation();
  const { discover } = t("buttons");
  
  return (
    <AnimatePresence>
      <m.div className={classes.sloganContainer}>
        <div className={classes.sloganContainer_text}>
          <h5>{description}</h5>
          <h1>{name}</h1>
        </div>
        <Button buttonName={discover} />
      </m.div>
    </AnimatePresence>
  );
};

export default HeroSloganContainer;
