//Hooks
import useScrollPosition from "../../hooks/use-scrollPosition";
import useScrollDirection from "../../hooks/use-scrollDirection";
import useWindowSize from "../../hooks/use-windowDimensions";
import { motion as m } from "framer-motion";

//CSS
import classes from "./BackToTop.module.css";

//Icons
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const BackToTop = () => {
  const scrollPosition = useScrollPosition();
  const scrollDirection = useScrollDirection();
  const size = useWindowSize();

  const handleScroll = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const buttonAnimation = {
    bottom:
      size.width < 640 && scrollDirection === "down"
        ? "0.6rem"
        : size.width > 640
        ? "3rem"
        : "5rem",
  };

  return (
    <m.div
      initial={false}
      animate={{ scale: scrollPosition < 300 ? 0 : 1 }}
      style={buttonAnimation}
      transition={{ duration: 0.3 }}
      className={`${classes.container} ringScaleButton`}
    >
      <div onClick={handleScroll}>
        <KeyboardArrowUpIcon style={{ fontSize: "2rem" }} />
      </div>
    </m.div>
  );
};

export default BackToTop;
