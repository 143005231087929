//Hooks
import { useRef } from "react";
import Lottie from "lottie-react";
import useImageLoader from "../../../../hooks/use-imageLoader";

//IconAnimation
import loading from "../../../../assets/anim/Animation - loading.json";

const ImageWrapper = ({ src, alt, photoClass }) => {
  const loadingRef = useRef(null);
  const imageLoaded = useImageLoader(src);

  return (
    <div className={photoClass}>
      {imageLoaded ? (
        <img
          src={src}
          alt={alt}
          data-swiper-parallax="30%"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      ) : (
        <div
          style={{ display: "flex", alignSelf: "center", marginInline: "auto" }}
        >
          <Lottie
            lottieRef={loadingRef}
            onComplete={() => {
              loadingRef.current?.goToAndStop(72, imageLoaded);
            }}
            animationData={loading}
            style={{ marginInline: "auto" }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageWrapper;
