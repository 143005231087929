//Hooks
import { useEffect, useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { useLocation } from "react-router-dom";

//Animations
import { mobileHeaderLogoAndPathAnimation } from "../../../../util/animationsVariants";

//Logo
import logo from "../../../../assets/images/logo/logo3.png";

//CSS
import classes from "./MobileHeaderLogoAndPath.module.css";

const MobileHeaderLogoAndPath = () => {
  const [splitStrings, setSplitStrings] = useState([]);

  const location = useLocation();
  const animation = mobileHeaderLogoAndPathAnimation({ location });

  useEffect(() => {
    const parts = location.pathname.split("/");
    const trimmedParts = parts.map((part) => part.trim());
    setSplitStrings(trimmedParts[trimmedParts.length -1].split("-").join(" "));
  },[location.pathname]);

  return (
    <div className={classes.logoAndPathContainer}>
      <m.div {...animation.logo}>
        {/* <img src={logo} alt="sienna" /> */}
        <h1>Sienna</h1>
      </m.div>
      <AnimatePresence>
        <m.div {...animation.path}>
          <span>{splitStrings}</span>
        </m.div>
      </AnimatePresence>
    </div>
  );
};

export default MobileHeaderLogoAndPath;
