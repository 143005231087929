//Hooks
import { IconContext } from "react-icons";

//Icons
import { IoIosArrowUp } from "react-icons/io";

//CSS
import classes from "./Arrow.module.css";

const Arrow = ({ name, menuName }) => {
  return (
    <span>
      <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
        <IoIosArrowUp
          className={name[menuName] ? classes.arrowUp : classes.arrowDown}
        />
      </IconContext.Provider>
    </span>
  );
};

export default Arrow;
