//Hooks
import { useSelector, useDispatch } from "react-redux";
import { setCart } from "../../../store/index";
import { useTranslation } from "react-i18next";
import Swiper from "../../../hooks/use-swiper";
import { SwiperSlide } from "swiper/react";
import { useRef } from "react";

//Components
import ShoppingCart from "./ShoppingCart.js";
import ShoppingCartForm from "./ShoppingCartForm.js";
import OrderSummary from "./OrderSummary";

//CSS
import classes from "./Cart.module.css";

const Cart = () => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);

  const handleCalculatePrice = (quantity, price) => {
    return quantity * price;
  };

  const calculateTotalPrice = (cartItems) => {
    return cartItems.reduce((total, item) => {
      return total + item.quantity * item.price;
    }, 0);
  };

  const totalPrice = calculateTotalPrice(cartItems);

  const handleRemoveItem = (itemName, itemPieces) => {
    const updatedCart = cartItems.filter(
      (item) => item.name !== itemName || item.pieces !== itemPieces
    );
    dispatch(setCart(updatedCart));
  };

  const generateOrderId = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // Extracting desired properties from each object
  const extractedData = cartItems.map((obj) => ({
    name: obj.name,
    pieces: obj.pieces,
    unitPrice: obj.price,
    totalPrice: obj.price * obj.quantity,
    quantity: obj.quantity,
  }));

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className={classes.container}>
      <Swiper
        scrollbar={false}
        spaceBetween={0}
        pagination={false}
        loop={false}
        slidesPerView={1}
        speed={500}
        allowTouchMove={false}
        forwardRef={swiperRef}
        swiperClass={classes.swiper}
      >
        <SwiperSlide className={classes.shoppingCartContainer}>
          <ShoppingCart
            handleCalculatePrice={handleCalculatePrice}
            handleRemoveItem={handleRemoveItem}
          />
          <OrderSummary
            totalPrice={totalPrice}
            callToAction={goToNextSlide}
            buttonName={t("buttons.checkout")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ShoppingCartForm
            generateOrderId={generateOrderId}
            extractedData={extractedData}
            totalPrice={totalPrice}
            goToPrevSlide={goToPrevSlide}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Cart;
