//photos routes
// import chocolates from "../../../assets/images/swiperNavigation/chocolates.png";
import cookies from "../../../assets/images/swiperNavigation/cookies.png";
import croissant from "../../../assets/images/swiperNavigation/croissant.png";
// import cupcakeBouquet from "../../../assets/images/swiperNavigation/cupcakeBouquet.png";
import cupcakes from "../../../assets/images/swiperNavigation/cupcakes.png";
// import macarons from "../../../assets/images/swiperNavigation/macarons.png";
// import muffins from "../../../assets/images/swiperNavigation/muffins.png";
import gifts from "../../../assets/images/swiperNavigation/gifts.png";


import ValentineBox from "../gift-ideas/valentine-box";
import BirthdayBox from "../gift-ideas/birthday-box";
import LemonTartlet from "../pastries/lemon-tartlet";
import VanillaFlan from "../pastries/vanilla-flan";
import ChocolateChipCookies from "../cookies/chocolate-chip-cookies";
import DarkChocolateCookies from "../cookies/dark-chocolate-cookies";
import MilkChocolateCookies from "../cookies/milk-chocolate-cookies";
import { CoconutCaramelCupcake } from "../cupcakes/coconut-caramel-cupcake";
import { TangerineAlmondCupcake } from "../cupcakes/tangerine-almond-cupcake";
import { WhiteChocolateMintCupcake } from "../cupcakes/white-chocolate-mint-cupcake";

export const products = [
  {
    name: "gift ideas",
    id: "1",
    path: "all-the-products/gift-ideas",
    img: "https://i.postimg.cc/HsW7tDm4/gift-Ideas.jpg",
    pathImg: gifts,
    hasSubMenu: true,
    subMenu: [ValentineBox, BirthdayBox],
  },
  {
    name: "pastries",
    id: "2",
    path: "all-the-products/pastries",
    img: "https://i.postimg.cc/MKGvQ709/croissants.jpg",
    pathImg: croissant,
    hasSubMenu: true,
    subMenu: [LemonTartlet, VanillaFlan],
  },
  {
    name: "cookies",
    id: "3",
    path: "all-the-products/cookies",
    img: "https://i.postimg.cc/wTPTRSjB/cookies.jpg",
    pathImg: cookies,
    hasSubMenu: true,
    subMenu: [ChocolateChipCookies, DarkChocolateCookies, MilkChocolateCookies],
  },
  {
    name: "cupcakes",
    id: "4",
    path: "all-the-products/cupcakes",
    img: "https://i.postimg.cc/gjH0dSz1/chocolate-cupcakes-from-scratch.jpg",
    pathImg: cupcakes,
    hasSubMenu: true,
    subMenu: [
      CoconutCaramelCupcake,
      TangerineAlmondCupcake,
      WhiteChocolateMintCupcake,
    ],
  },
];
