//Hooks
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { IconContext } from "react-icons";
import useEnterKeyPress from "../../../hooks/use-enterKeyPress";

//Components
import SearchInput from "./SearchInput";

//Data
import { products } from "../../../data/products/all-products/all-products";

//CSS
import classes from "./SearchBox.module.css";

const SearchBox = () => {
  const isDesktopSearchActive = useSelector(
    (state) => state.isSearchInputActive.desktop
  );
  const inputRef = useRef(null);

  useEffect(() => {
    if (isDesktopSearchActive) {
      inputRef.current.focus();
    }
  }, [isDesktopSearchActive]);

  const arrayOfSubMenuPaths = products.flatMap((item) =>
    item.subMenu.map((menu) => menu.searchQueryPath)
  );

  const { inputValue, handleKeyDown, handleInputChange } =
    useEnterKeyPress(arrayOfSubMenuPaths);

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <AnimatePresence initial={false}>
        {isDesktopSearchActive && (
          <div key={isDesktopSearchActive} className={classes.openSearch}>
            <SearchInput
              forwardRef={inputRef}
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
            />
          </div>
        )}
      </AnimatePresence>
    </IconContext.Provider>
  );
};

export default SearchBox;
