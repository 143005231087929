//Hooks
import { motion as m } from "framer-motion";
import Lottie from "lottie-react";

//CSS
import classes from "./Animations.module.css";

//iconAnimation
import circles from "../../assets/anim/Animation - circles.json";

const Animation = ({ animationsStyle, img, styleAnimation, styleContainer }) => {
  return (
    <m.div {...animationsStyle} className={`${classes.search} ${styleContainer}`}>
      <div className={styleAnimation}>
        <Lottie animationData={circles} />
        <div className={classes.glass}>
          <img src={img} alt="animation"/>
        </div>
      </div>
    </m.div>
  );
};

export default Animation;
