//Hooks
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setMenuVisibility, setLanguageVisibility, setCartVisibility } from "../../../../store/index";
import useWindowSize from "../../../../hooks/use-windowDimensions";

//Components
import CustomHamburgerButton from "./CustomHamburgerButton";

//CSS
import classes from "./HamburgerButton.module.css";

const HamburgerButton = () => {
  const { t } = useTranslation()

  const size = useWindowSize();
  const dispatch = useDispatch();
  const areMenusOpened = useSelector((state) => state.areMenusOpened);

  const onClickMenuHandler = () => {
    dispatch(setMenuVisibility(!areMenusOpened.menu));
    dispatch(setCartVisibility(false));
    dispatch(setLanguageVisibility(false));
  };

  let products = size.width > 1100 ? t(`links.products`) : "";

  return (
    <div className={classes.header_hamburger} onClick={onClickMenuHandler}>
      <CustomHamburgerButton />
      <span className={classes.list} >
        {products}
      </span>
    </div>
  );
};

export default HamburgerButton;
