//Hooks
import { Helmet } from "react-helmet";

const MetaTags = (props) => {
  const tags = props.tags;

  return (
    <Helmet>
      <title>{tags.title}</title>
      <meta name="description" content={tags.description} />
      <meta name="theme-color" content="#544841" />
      <meta
        name="keywords"
        content={tags.keywords}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    </Helmet>
  );
};

export default MetaTags;
