export const legalNoticeData = [
  {
    id: 0,
    header: "Data protection",
    text: [
      {
        para: "Sienna pastry boutique has a data file on its customers which will be used to process its customers' orders. Sienna pastry boutique does not transfer any of its data to commercial partners. The law n° 78-17 of January 6, 1978 relating to data processing, obliges Sienna pastry boutique to protect all the data concerning its customers. The latter have the right to demand corrections or modifications to the information concerning them. They can request a deletion of their data by contacting Sienna pastry boutique directly by telephone or e-mail.",
      },
    ],
  },
  {
    id: 1,
    header: "Updating the site",
    text: [
      {
        para: "All the information and data visible on the Sienna pastry boutique website can be withdrawn or modified by the latter. The customer will not be able to respond to any request for compensation for damage.",
      }
    ],
  },
  {
    id: 2,
    header: "Copyright - Photo credits",
    text: [
      {
        para: "The entire Sienna pastry boutique website (photos, information, data, etc.) The data, information and photos circulating on the website are guaranteed by the Data Protection Act, providing for total protection of the latter. No reproduction is therefore authorized on all the data posted on the Sienna pastry boutique site, under penalty of sanction.",
      }
      
    ],
  },
  {
    id: 3,
    header: "Company Information - Sienna pastry boutique",
    text: [
      {
        para: "Telephone number: 070359589",
      },
      {
        para: "The website is published by Sienna pastry boutique",
      },
    ],
  },
  {
    id: 4,
    header: "Host",
    text: [
      {
        para: "The website is hosted by MK Host",
      },
    ],
  },
  {
    id: 5,
    header: "Credits",
    text: [
      {
        para: "The website was designed and developed by Gregori",
      }
    ],
  },
  
];