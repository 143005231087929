//Hooks
import {motion as m} from "framer-motion"
import { IconContext } from "react-icons"

//Components
import MobileHeaderBackLocation from "./MobileHeaderBackLocation";
import MobileHeaderLogoAndPath from "./MobileHeaderLogoAndPath";
import MobileHeaderOptions from "./MobileHeaderOptions";

//CSS
import classes from "./MobileHeader.module.css";

const MobileHeader = () => {
  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <m.header className={classes.mobileHeader}>
        <MobileHeaderBackLocation />
        <MobileHeaderLogoAndPath />
        <MobileHeaderOptions />
      </m.header>
    </IconContext.Provider>
  );
};

export default MobileHeader;
