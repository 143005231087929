//Hooks
import Lottie from "lottie-react";
import { motion as m } from "framer-motion";

//iconAnimation
import notFound from "../../../assets/anim/Animation - 404.json";

//CSS
import classes from "./Error.module.css";

const Error = ({ message, animationsStyle }) => {
  return (
    <m.div {...animationsStyle} className={classes.container}>
      <Lottie animationData={notFound} />
      <h1>{message}</h1>
    </m.div>
  );
};

export default Error;
