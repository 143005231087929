//Hooks
import { useTranslation } from "react-i18next";

const SearchInput = ({
  forwardRef,
  inputValue,
  handleInputChange,
  handleKeyDown,
  handleBlur,
  handleFocus,
}) => {
  const { t } = useTranslation();
  return (
    <input
      ref={forwardRef}
      type="search"
      id="searchDesktop"
      placeholder={t("other.search_products")}
      autoComplete="off"
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      // onBlur={handleBlur}
      onFocus={handleFocus}
      value={inputValue}
    />
  );
};

export default SearchInput;
