//Hooks
import SwiperCore, {
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  Parallax,
} from "swiper";
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Parallax]);

export default ({
  spaceBetween,
  slidesPerView,
  pagination,
  scrollbar,
  loop,
  parallax,
  speed,
  autoplay,
  onResize,
  allowTouchMove,
  breakpoints,
  forwardRef,
  style,
  swiperClass,
  children,
}) => {
  return (
    <Swiper
      className={swiperClass}
      onInit={(swiper) => {
        swiper.navigation.init();
        swiper.navigation.update();
      }}
      modules={[Navigation, Autoplay, Pagination, A11y, Parallax]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      pagination={pagination}
      scrollbar={scrollbar}
      loop={loop}
      observer={true}
      parallax={parallax}
      speed={speed}
      autoplay={autoplay}
      allowTouchMove={allowTouchMove}
      onResize={onResize}
      ref={forwardRef}
      breakpoints={breakpoints}
      style={style}
    >
      {children}
    </Swiper>
  );
};
