//Hooks
import { IconContext } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { setOptionsVisibility } from "../../../../store/index";

//CSS
import classes from "./MobileHeaderOptions.module.css";

//Icons
import { RxDotsHorizontal } from "react-icons/rx";

const MobileHeaderOptions = () => {
  const dispatch = useDispatch();
  const isOptionsOpen = useSelector((state) => state.areMenusOpened.options);

  const optionsHandler = () => {
    dispatch(setOptionsVisibility(!isOptionsOpen));
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <div position={{ position: "relative" }}>
        <span className={classes.iconStyles} onClick={optionsHandler}>
          <RxDotsHorizontal
            className={classes.button}
            style={{ fontSize: "1.2rem" }}
          />
        </span>
      </div>
    </IconContext.Provider>
  );
};

export default MobileHeaderOptions;
