//Hooks
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { motion as m, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/use-windowDimensions";

//Components
import DropdownSubMenu from "./DropdownSubMenu";
import Arrow from "../../../ui/Arrow";

//CSS
import classes from "./Menu.module.css";

//Data
import { products } from "../../../../data/products/all-products/all-products";

const allProducts = {
  name: "all products",
  path: "all-the-products",
  hasSubMenu: false,
  img: "https://i.postimg.cc/d0Q5rS1h/all-Products.jpg",
};

const Menu = ({ photoMenuToShowHandler, setPhoto }) => {
  const { t } = useTranslation()

  const navigate = useNavigate();
  const size = useWindowSize();

  const [name, setName] = useState({});
  const isMenuOpened = useSelector((state) => state.areMenusOpened.menu);

  const productsData = [allProducts, ...products]

  const clickedSubMenu = (name) => {
    setName((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  useEffect(() => {
    if (!isMenuOpened) {
      setName({});
    }
  }, [isMenuOpened]);

  useEffect(() => {
    setPhoto({
      photo: productsData[0].img,
      name: productsData[0].name,
      path: productsData[0].path,
    });
  }, []);

  return (
    <m.ul className={classes.ul}>
      {productsData.map((menu, i) => {
        return (
          <li
            onClick={() =>
              clickedSubMenu(menu.name)
            }
            key={i}
            onMouseEnter={() =>
              photoMenuToShowHandler({
                photo: menu.img,
                name: menu.name,
                path: menu.path,
              })
            }
          >
            <span className="list" onClick={() => navigate(menu.path)}>
              {t(`product_categories.${menu.name}`)}
            </span>
            {menu.subMenu && size.width < 1100 && (
              <Arrow
                name={name}
                menuName={menu.name}
                clickedSubMenu={clickedSubMenu}
              />
            )}
            {menu.subMenu && size.width > 1100 && (
              <DropdownSubMenu data={menu.subMenu} menuName={menu.name}/>
            )}
            <AnimatePresence>
              {menu.subMenu && size.width < 1100 && name[menu.name] && (
                <DropdownSubMenu data={menu.subMenu} menuName={menu.name}/>
              )}
            </AnimatePresence>
          </li>
        );
      })}
      {size.width < 1100 && size.width > 640 && (
        <>
          <li>Contact</li>
          <li>About</li>
        </>
      )}
    </m.ul>
  );
};

export default Menu;
