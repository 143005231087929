//Hooks
import { useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { IconContext } from "react-icons";

//Component
import Arrow from "./Arrow";

//CSS
import classes from "./Dropdown.module.css";

//Animations
import { subMenuAnimation } from "../../util/animationsVariants";

//Icons
import { PiDotOutlineFill } from "react-icons/pi";

const Dropdown = (props) => {
  const data = props.data;

  const [clickedTopicHeader, setClickedTopicHeader] = useState({});

  const handleClickedTopicHeader = (id) => {
    setClickedTopicHeader((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <m.ol className={classes.container} {...props.animations}>
        {data.map((item, i) => {
          return (
            <li key={i} onClick={() => handleClickedTopicHeader(item.id)}>
              <div>
                <h6>{item.header}</h6>
                <Arrow name={clickedTopicHeader} menuName={item.id} />
              </div>
              <AnimatePresence>
                {clickedTopicHeader[item.id] && (
                  <m.div {...subMenuAnimation}>
                    <div className={classes.para}>
                      {item.text.map((item, i) => {
                        return (
                          <span key={i}>
                            <span>
                              <PiDotOutlineFill
                                style={{
                                  fontSize: "1.3rem",
                                }}
                              />
                            </span>
                            <span>{item.para}</span>
                          </span>
                        );
                      })}
                    </div>
                  </m.div>
                )}
              </AnimatePresence>
            </li>
          );
        })}
      </m.ol>
    </IconContext.Provider>
  );
};

export default Dropdown;
