import { createSlice, configureStore } from "@reduxjs/toolkit";

const isInViewSlice = createSlice({
  name: "isInView",
  initialState: { view: false },
  reducers: {
    isInView(state, action) {
      state.view = action.payload;
    },
  },
});

const areMenusOpenedSlice = createSlice({
  name: "areMenusOpened",
  initialState: { menu: false, cart: false, language: false, options: false },
  reducers: {
    setMenuVisibility(state, action) {
      state.menu = action.payload;
    },
    setCartVisibility(state, action) {
      state.cart = action.payload;
    },
    setLanguageVisibility(state, action) {
      state.language = action.payload;
    },
    setOptionsVisibility(state, action) {
      state.options = action.payload;
    },
  },
});

const isNotificationSlice = createSlice({
  name: "isNotification",
  initialState: {
    isOpen: false,
    message: "",
    color: "",
    icon: "",
  },
  reducers: {
    isNotification(state, action) {
      state.isOpen = action.payload;
    },
    notificationMessage(state, action) {
      state.message = action.payload;
    },
    notificationColor(state, action) {
      state.color = action.payload;
    },
    notificationIcon(state, action) {
      state.icon = action.payload;
    },
  },
});

const overlayHandlerSlice = createSlice({
  name: "isOverlayClicked",
  initialState: { isOverlayClicked: false },
  reducers: {
    isOverlayClicked(state, action) {
      state.isOverlayClicked = action.payload;
    },
  },
});

const inputSlice = createSlice({
  name: "input",
  initialState: {
    value: "",
  },
  reducers: {
    setInputValue: (state, action) => {
      state.value = action.payload;
    },
    clearInputValue: (state) => {
      state.value = "";
    },
  },
});

const suggestionsSlice = createSlice({
  name: "suggestions",
  initialState: {
    suggestions: [],
  },
  reducers: {
    setSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
  },
});

const searchResultsProductsSlice = createSlice({
  name: "searchResults",
  initialState: {
    searchResults: [],
    searchValue: "",
  },
  reducers: {
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
  },
});

const searchInputSlice = createSlice({
  name: "isSearchInputActive",
  initialState: {
    desktop: false,
    mobile: false,
  },
  reducers: {
    setDesktopSearch: (state, action) => {
      state.desktop = action.payload;
    },
    setMobileSearch: (state, action) => {
      state.mobile = action.payload;
    },
  },
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
  },
  reducers: {
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    addItemToCart: (state, action) => {
      const { updatedProductData, productData, boxSize, quantity } =
        action.payload;

      const existingProduct = state.cart.find(
        (item) =>
          item.name === productData.name &&
          item.pieces === boxSize.pieces &&
          item.price === boxSize.price
      );

      if (existingProduct) {
        existingProduct.quantity += quantity;
      } else {
        state.cart.push(updatedProductData);
      }
    },
    updateItemQuantity: (state, action) => {
      const { itemName, itemPieces, newQuantity } = action.payload;

      const itemToUpdate = state.cart.find(
        (item) => item.name === itemName && item.pieces === itemPieces
      );

      if (itemToUpdate) {
        itemToUpdate.quantity = newQuantity;
      }
    },
  },
});

const activeProductCategorySlice = createSlice({
  name: "activeProductCategory",
  initialState: {id: 0},
  reducers: {
    setProductCategory(state, action) {
      state.id = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    view: isInViewSlice.reducer,
    areMenusOpened: areMenusOpenedSlice.reducer,
    isNotification: isNotificationSlice.reducer,
    isOverlayClicked: overlayHandlerSlice.reducer,
    input: inputSlice.reducer,
    suggestions: suggestionsSlice.reducer,
    cart: cartSlice.reducer,
    searchResults: searchResultsProductsSlice.reducer,
    isSearchInputActive: searchInputSlice.reducer,
    activeProductCategory: activeProductCategorySlice.reducer,
  },
});

export const isInViewActions = isInViewSlice.actions;
export const {
  setMenuVisibility,
  setCartVisibility,
  setLanguageVisibility,
  setOptionsVisibility,
} = areMenusOpenedSlice.actions;
export const {
  isNotification,
  notificationMessage,
  notificationColor,
  notificationIcon,
} = isNotificationSlice.actions;
export const { isOverlayClicked } = overlayHandlerSlice.actions;
export const { setInputValue, clearInputValue } = inputSlice.actions;
export const { setSuggestions } = suggestionsSlice.actions;
export const { setCart, addItemToCart, updateItemQuantity } = cartSlice.actions;
export const { setSearchResults, setSearchValue } =
  searchResultsProductsSlice.actions;
export const { setDesktopSearch, setMobileSearch } = searchInputSlice.actions;
export const { setProductCategory } = activeProductCategorySlice.actions;

export default store;
