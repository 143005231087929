//Hooks
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

//Components
import Dropdown from "../../ui/Dropdown";
import SwiperMenu from "../../ui/SwiperMenu";

//Animations
import { clickedTopicAnimation } from "../../../util/animationsVariants";

//CSS
import classes from "./FrequentlyAskedQuestions.module.css";

//Data
import { faqData } from "../../../data/faqData";

const FrequentlyAskedQuestions = () => {
  const [clickedTopic, setClickedTopic] = useState(0);

  const handleClickedNameTopic = (id) => {
    setClickedTopic(id);
  };

  const swiperSlidesStyles = (id) => {
    return {
      backgroundColor: id === clickedTopic ? "var(--brown)" : "transparent",
      color: id === clickedTopic ? "var(--sandWhite)" : "var(--brown)",
    };
  };

  return (
    <section className={classes.container}>
      <div className={classes.navigation}>
        <SwiperMenu
          data={faqData}
          slidesStyle={swiperSlidesStyles}
          onClickFunction={handleClickedNameTopic}
        />
      </div>
      <div className={classes.content}>
        <AnimatePresence>
          {faqData.map((item, i) => {
            return (
              item.id === clickedTopic && (
                <Dropdown
                  data={item.topics}
                  animations={clickedTopicAnimation}
                  key={i}
                />
              )
            );
          })}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default FrequentlyAskedQuestions;
