//Hooks
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

//CSS
import classes from "./SwiperMenu.module.css";

const SwiperMenu = ({
  data,
  onClickFunction,
  slidesStyle,
  sliderStyle,
  slidesClassName,
}) => {
  const { t } = useTranslation();

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView="auto"
      className={`${classes.menu} ${sliderStyle}`}
    >
      {data.map((item, i) => (
        <SwiperSlide
          className={`${classes.menuSlides} ${slidesClassName}`}
          key={i}
          onClick={() => onClickFunction(i)}
          style={slidesStyle(i, item.display)}
        >
          <h6>
            {item.name ? t("product_categories." + item.name) : null}
            {item.title ? t("product_categories." + item.title) : null}
          </h6>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperMenu;
