//CSS
import classes from "./BackgroundSweets.module.css";

//Media
import backgroundSweets from "../../../assets/images/backgroundSweets/1.png";

const BackgroundSweets = ({ pageName }) => {
  return (
    <section className={classes.container}>
      <img src={backgroundSweets} alt="sweets" />
      <span>
        <h1>{pageName}</h1>
      </span>
    </section>
  );
};

export default BackgroundSweets;
