//Hooks
import { motion as m } from "framer-motion";
import { useParams } from "react-router-dom";
import MetaTags from "../../hooks/use-metaTags";
import { useTranslation } from "react-i18next";

//Components
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ElementWrapper from "../layout/main/ElementWrapper";
import Guarantee from "../layout/main/Guarantee";
import LocationMap from "../layout/main/LocationMap";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import ProductDetailsComponent from "../ui/Product/ProductDetailsComponent";
import Error from "../layout/main/Error";

//Data
import { products } from "../../data/products/all-products/all-products";
import { CupcakeOfTheMonth } from "../../data/products/cupcake-of-the-month/cupcake-of-the-month";
import { ProductDetailsTags } from "../../data/metaTags/productDetailsTags";

const ProductDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const id = params.productId;
  const category = params.category;

  const arrOfProducts = [...products, ...CupcakeOfTheMonth];

  const subMenuArrays = arrOfProducts.map((item) => item.subMenu);

  const flattenedSubMenu = [].concat(...subMenuArrays);

  function findProductByPath(productArr, targetPath) {
    return productArr.find(
      (product) => product.path.toLowerCase() === targetPath.toLowerCase()
    );
  }

  const data = findProductByPath(flattenedSubMenu, `${category}/${id}`);

  const errorMessage = `${t("notifications.product404")} ${id} ${t("notifications.product404_2")}`;

  const productDescription = data?.productInfo?.[0]?.description;

  return (
    <m.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }} // Exit animation
      transition={{ duration: 0.3 }}
    >
      <MetaTags tags={ProductDetailsTags(data?.name, productDescription)} />
      <ElementWrapperFullWidth>
        <LocationPathGuide backgroundColor={"var(--sandWhite)"} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="aboutUs">
        {data ? (
          <ProductDetailsComponent data={data} />
        ) : (
          <Error message={errorMessage} />
        )}
        <Guarantee />
      </ElementWrapper>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <LocationMap />
      </ElementWrapperFullWidth>
    </m.div>
  );
};

export default ProductDetail;
