//Hooks
import { useNavigate } from "react-router-dom";
import MetaTags from "../../hooks/use-metaTags";
import { useTranslation } from "react-i18next";

//Components
import ProductSwiper from "../layout/swipers/ProductSwiper";
import RouteSwiper from "../layout/swipers/RouteSwiper";
import BannerSwiper from "../layout/swipers/bannerSwiper/BannerSwiper";
import Presentation from "../layout/main/Presentation";
import ElementWrapper from "../layout/main/ElementWrapper";
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ComponentSplitter from "../layout/main/ComponentSplitter";
import FollowUsOn from "../layout/main/FollowUsOn";
import LocationMap from "../layout/main/LocationMap";

//Data
import { homeTags } from "../../data/metaTags/homeTags";
import { CupcakeOfTheMonth } from "../../data/products/cupcake-of-the-month/cupcake-of-the-month"

const Home = () => {
  const monthName = new Date().toLocaleString("en-us", { month: "long" });
  const currentMonth = new Date().getMonth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    title_click,
    title_browse,
    subtitle_cupcake,
    subtitle_product_categories,
    subtitle_location,
  } = t("component_splitter");

  const { title, subtitle } = t("product_origins");

  const month = t(`month_names.${monthName}`);

  return (
    <>
      <MetaTags tags={homeTags} />
      <BannerSwiper />
      <ComponentSplitter
        backgroundColor={"var(--sandWhite)"}
        className="flavourOfTheMonth"
        title={title_click}
        subtitle={subtitle_cupcake + " " + month}
      />
      <ElementWrapper backgroundColor={"var(--sandWhite)"}>
        <ProductSwiper data={CupcakeOfTheMonth[currentMonth].subMenu}/>
      </ElementWrapper>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <ElementWrapper>
          <Presentation
            paragraph={subtitle}
            title={title}
            callToAction={() => navigate("/origins-of-our-ingredients")}
          />
        </ElementWrapper>
      </ElementWrapperFullWidth>
      <ComponentSplitter
        backgroundColor={"var(--sandWhite)"}
        title={title_browse}
        subtitle={subtitle_product_categories}
      />
      <ElementWrapperFullWidth
        backgroundColor={"var(--sandWhite)"}
        class="pageRoutesWrapper"
      >
        <RouteSwiper />
      </ElementWrapperFullWidth>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <FollowUsOn />
      </ElementWrapperFullWidth>
      <ComponentSplitter
        backgroundColor={"var(--sandWhite)"}
        title={title_click}
        subtitle={subtitle_location}
      />
      <ElementWrapperFullWidth backgroundColor={"var(--sandWhite)"}>
        <LocationMap />
      </ElementWrapperFullWidth>
    </>
  );
};

export default Home;
