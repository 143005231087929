//CSS
import classes from "./AboutUs.module.css";

//Photos
import photo from "../../../assets/images/other/Baking-Pantry-IG-3.png";

const AboutUs = () => {
  return (
    <div className={`${classes.container} containerStyle`}>
      <div>
        <div className={classes.paraContainer}>
          <p>
            Welcome to Sienna Pastry Boutique, where the artistry of baking
            meets the delight of indulgence. At our boutique pastry shop, we
            take pride in crafting extraordinary pastries that transcend the
            ordinary, creating a symphony of flavors that dance on your palate.
            Every confection is a masterpiece, meticulously handcrafted by our
            skilled artisans who infuse passion and creativity into each
            delightful bite. Our journey began with a simple belief – that
            exceptional pastries have the power to elevate moments, turning the
            ordinary into the extraordinary. From decadent cakes to delicate
            pastries, our menu is a celebration of the finest ingredients,
            expert craftsmanship, and a commitment to delivering an unparalleled
            sensory experience. Step into our world and immerse yourself in a
            realm of sweetness where innovation meets tradition. Whether you're
            savoring our signature éclairs, enjoying a slice of a meticulously
            layered cake, or exploring our ever-evolving selection of delightful
            treats, we invite you to experience the magic of Sienna Pastry
            Boutique. Indulge in the art of pastry perfection – where every bite
            is a moment to be savored, and every visit is an invitation to
            celebrate the extraordinary in life. Welcome to a haven for pastry
            enthusiasts, where passion meets pastry in every delectable
            creation.
          </p>
        </div>
        <div className={classes.photoContainer}>
          <img src={photo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
