//Hooks
import MetaTags from "../../hooks/use-metaTags";

//Components
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ElementWrapper from "../layout/main/ElementWrapper";
import Guarantee from "../layout/main/Guarantee";
import LocationMap from "../layout/main/LocationMap";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import AllProductCategories from "../ui/Product/AllProductCategories";
import AllProductCategoriesHeader from "../ui/Product/AllProductCategoriesHeader";

//Data
import { allProductCategoriesTags } from "../../data/metaTags/allProductCategoriesTags";

const AllProducts = () => {
  return (
    <>
      <MetaTags tags={allProductCategoriesTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide backgroundColor={"var(--sandWhite)"} />
        <AllProductCategoriesHeader />
      </ElementWrapperFullWidth>
      <ElementWrapper class="aboutUs">
        
        <AllProductCategories />
        <Guarantee />
      </ElementWrapper>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <LocationMap />
      </ElementWrapperFullWidth>
    </>
  );
};

export default AllProducts;
