//Hooks
import { motion as m, useInView } from "framer-motion";
import { useMemo, useRef } from "react";

//Components
import LinkToProductDetail from "./LinkToProductDetail";

const ProductItem = ({ prod }) => {
  const productItemRef = useRef(null);
  const isInViewContainer = useInView(productItemRef, { once: true });
  const productData = useMemo(() => prod, [prod]);

  return (
    <m.div
      ref={productItemRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInViewContainer ? 1 : 0 }}
      transition={{ duration: 0.7 }}
    >
      <LinkToProductDetail
        isVisible={isInViewContainer}
        productData={productData}
      />
    </m.div>
  );
};

export default ProductItem;
