//Hooks
import { useTranslation } from "react-i18next";

//CSS
import classes from "./LocationMap.module.css";

//Icons
import { ImLocation } from "react-icons/im";

const LocationMap = () => {
  const { t } = useTranslation();
  return (
    <section className={classes.container}>
      <div></div>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://maps.app.goo.gl/H4TEj93QzQv56ctj9"
      >
        <h3>{t("other.location_city")}</h3>
        <div className={classes.wrapper}>
          <div>
            <img src="https://i.postimg.cc/ZqnXt7Ht/IMG-8110.jpg" alt="shop" />
          </div>
          <div>
            <span>
              <ImLocation />
              &ensp;
              {t("other.location_municipality")}
            </span>
            <span>{t("other.location_street")}</span>
            <span>
              <span>{t("other.work_days")}</span>
              <span>{t("other.work_hours")}</span>
            </span>
          </div>
        </div>
      </a>
      <img
        src="https://i.postimg.cc/yxXYBHtf/map-Custom-Location.png"
        alt="location"
      />
    </section>
  );
};

export default LocationMap;
