//Hooks
import { Link } from "react-router-dom";
import { motion as m, AnimatePresence } from "framer-motion";
import { IconContext } from "react-icons";

//Animations
import { liAnimations } from "../../../util/animationsVariants";

const Paths = ({ arr, isSearchClicked, children, delayAnimation }) => {
  const handlePaths = (name) => {
    return getLastSegment(replaceDashWithSpace(name));
  };

  const replaceDashWithSpace = (textWithDash) => {
    return textWithDash.replace(/-/g, " ");
  };

  const getLastSegment = (str) => {
    const segments = str.split('/');
    return segments[segments.length - 1];
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      {arr.map((item, i) => {
        return (
          <li key={i}>
            <Link to={item}>
              <AnimatePresence>
                {isSearchClicked && (
                  <m.span {...liAnimations(i, delayAnimation, 0.4)} >
                    <span>{children}</span>
                    <span style={{ textTransform: "none" }}>
                      {handlePaths(item)}
                    </span>
                  </m.span>
                )}
              </AnimatePresence>
            </Link>
          </li>
        );
      })}
    </IconContext.Provider>
  );
};

export default Paths;
