//Hooks
import { useSelector} from "react-redux";
import useWindowSize from "../../../hooks/use-windowDimensions";

//Components
import Cart from "./Cart";
import EmptyCart from "./EmptyCart";

//CSS
import classes from "./DropdownCart.module.css";

const DropdownCart = () => {
  const size = useWindowSize();
  const isCartEmpty = useSelector((state) => state.cart.cart.length < 1);

  const containerStyle = {
    height: !isCartEmpty && size.width < 640 ?  "100dvh" : "100%" 
  }

  return (
    <div className={classes.container}  style={{...containerStyle}}>
      {isCartEmpty ? <EmptyCart /> : <Cart />}
    </div>
  );
};

export default DropdownCart;
