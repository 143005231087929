//Hooks
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateItemQuantity } from "../../../store";
import { useTranslation } from "react-i18next";

//Components
import QuantityContainer from "../Product/QuantityContainer";

//CSS
import classes from "./CartItem.module.css";

//Icons
import { PiPlusLight } from "react-icons/pi";

const CartItem = ({ item, handleRemoveItem, handleCalculatePrice }) => {
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(item.quantity);
  const dispatch = useDispatch();

  const handleUpdateQuantity = (itemName, itemPieces, newQuantity) => {
    dispatch(updateItemQuantity({ itemName, itemPieces, newQuantity }));
  };

  useEffect(() => {
    handleUpdateQuantity(item.name, item.pieces, quantity);
  }, [quantity, dispatch]);

  return (
    <li>
      <div className={classes.imgContainer}>
        <img src={item.imgs[0]} alt="product"/>
      </div>
      <div className={classes.productDetail}>
        <h4>{item.name}</h4>
        <p>
          {item.pieces} {t("other.pieces")}
        </p>
        <p className={classes.price}>
          {item.price} {t("other.currency")}
        </p>
      </div>
      <div>
        <div className={classes.quantityContainer}>
          <QuantityContainer
            quantity={quantity}
            setQuantity={setQuantity}
            classWrapper={classes.quantityWrapper}
          />
        </div>
      </div>
      <div className={classes.priceContainer}>
        <p>
          {item.price} {t("other.currency")}
        </p>
      </div>
      <div className={classes.totalUnitPrice}>
        <p>
          {handleCalculatePrice(item.quantity, item.price)}{" "}
          {t("other.currency")}
        </p>
      </div>
      <div
        onClick={() => handleRemoveItem(item.name, item.pieces)}
        className={classes.trash}
      >
        <PiPlusLight fontSize={"2rem"}/>
      </div>
    </li>
  );
};

export default CartItem;
