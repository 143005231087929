//Hooks
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//CSS
import classes from "./LocationPathGuide.module.css";

//Icons
import { BiSolidLeftArrow } from "react-icons/bi";
import { FaChevronLeft } from "react-icons/fa6";
import { BsHouse } from "react-icons/bs";

const LocationPathGuide = ({ backgroundColor }) => {
  const {t} = useTranslation()

  const [splitStrings, setSplitStrings] = useState([]);
  const [paths, setPaths] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToPrevPath = (arr) => {
    return arr.slice(0, -1).join("/");
  }

  const showCurrentPaths = (arr) => {
    return arr.map((part) => part.trim().split("-").join(" "));
  }

  useEffect(() => {
    const parts = location.pathname.split("/");
   
    setPaths(navigateToPrevPath(parts));
    setSplitStrings(showCurrentPaths(parts));
    
  }, [location.pathname]);

  const handleClickHome = () => {
    navigate("/");
  };

  const handleClickBack = () => {
    navigate(paths);
  };


  const handleBackButtonStyle = () => {
    const bool = splitStrings.length <= 2;

    return { pointerEvents: bool ? "none" : null, opacity: bool ? 0.3 : 1 };
  };

  return (
    <section
      className={classes.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <ol>
        <li onClick={handleClickHome} className={classes.backHome}>
          <BsHouse style={{ fontSize: "1.4rem" }} />
          <span>{t("links.home")}</span>
        </li>
        <li className={classes.back}>
          <div onClick={handleClickBack} style={handleBackButtonStyle()}>
            <FaChevronLeft style={{ fontSize: "1.2rem" }} />
          </div>
          <ul className={classes.pathNames}>
            {splitStrings.map((item, i) => (
              <li key={i}>
                <span>{item}</span>
                <span>
                  <BiSolidLeftArrow style={{ fontSize: "0.8rem" }} />
                </span>
              </li>
            ))}
          </ul>
        </li>
      </ol>
    </section>
  );
};

export default LocationPathGuide;
