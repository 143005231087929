//Hooks
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MetaTags from "./hooks/use-metaTags";

//Pages
import RootLayout from "./components/pages/Root";
import ErrorPage from "./components/pages/ErrorPage";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import ProductCategories from "./components/pages/ProductCategories";
import FAQ from "./components/pages/FAQ";
import LegalNotice from "./components/pages/LegalNotice";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import OriginOfOurIngredients from "./components/pages/OriginsOfOurIngredients";
import ProductDetails from "./components/pages/ProductDetail";
import IndividualProductCategory from "./components/pages/IndividualProductCategory";
import SearchResults from "./components/pages/SearchResults";

import SiteLinks from "./Schema/SiteLinksSchemas"

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "about", element: <About /> },
      { path: "contact", element: <Contact /> },
      { path: "faq", element: <FAQ /> },
      { path: "legal-notice", element: <LegalNotice /> },
      { path: "terms-and-conditions", element: <TermsAndConditions /> },
      {
        path: "origins-of-our-ingredients",
        element: <OriginOfOurIngredients />,
      },
      { path: "search-results-products", element: <SearchResults /> },
      { path: "all-the-products", element: <ProductCategories /> },
      {
        path: "all-the-products/:category",
        element: <IndividualProductCategory />,
      },
      {
        path: "all-the-products/:category/:productId",
        element: <ProductDetails />,
      },
    ],
  },
]);

const tags = {
  title: "Siena",
  description: "A symphony of flavours baked with passion",
  keywords: "pastries, cookies, macarons, muffins, cupcakes, chocolates",
};

function App() {
  return (
    <>
      <MetaTags tags={tags} />
      <RouterProvider router={router} />
      <SiteLinks/>
    </>
  );
}

export default App;
