//Hooks
import MetaTags from "../../hooks/use-metaTags";
import { useTranslation } from "react-i18next";

//Components
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ElementWrapper from "../layout/main/ElementWrapper";
import BackgroundSweets from "../layout/main/BackgroundSweets";
import Guarantee from "../layout/main/Guarantee";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import OriginOfOurEng from "../layout/main/OriginOfOurEng";

//Data
import { originsOfOutIngredientsTags } from "../../data/metaTags/originsOfOurIngredientsTags";

const OriginOfOurIngredients = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTags tags={originsOfOutIngredientsTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide />
        <BackgroundSweets pageName={t("pages.origins of our ingredients")} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="originsOfOurIng">
        <OriginOfOurEng />
        <Guarantee />
      </ElementWrapper>
    </>
  );
};

export default OriginOfOurIngredients;
