//Hooks
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion as m, AnimatePresence } from "framer-motion";

//Data
import { languages } from "../../../../../data/languagesData";

//CSS
import classes from "./Languages.module.css";

//Icons
import { GrRadialSelected } from "react-icons/gr";
import { GrRadial } from "react-icons/gr";

//Animations
import { subMenuAnimation } from "../../../../../util/animationsVariants";

const MobileLanguages = ({ icon, name, isLanguageOpen, setIsLanguageOpen }) => {
  const { i18n } = useTranslation();
  const [active, setActive] = useState(i18n.language);

  const handleLanguageChange = (tag) => {
    i18n.changeLanguage(tag);
    setActive(tag);
    setIsLanguageOpen(false);
  };

  return (
    <li className={classes.languageMenuContainer}>
      <span
        className={`${classes.languageIconAndNameContainer} ${
          isLanguageOpen ? classes.languageIconAndNameContainerAfter : null
        }`}
        onClick={() => setIsLanguageOpen((prev) => !prev)}
      >
        <span>{icon}</span>
        <span>{name}</span>
      </span>
      <AnimatePresence>
        {isLanguageOpen && (
          <m.ol className={classes.languagesMenu} {...subMenuAnimation}>
            <div>
              {languages().map(({ tag, name, flag }, i) => {
                return (
                  <li key={i} onClick={() => handleLanguageChange(tag)}>
                    {flag}
                    <span>{name}</span>
                    <span className={classes.radial}>
                      {active === tag ? (
                        <GrRadialSelected fontSize={"0.7rem"} />
                      ) : (
                        <GrRadial fontSize={"0.7rem"} />
                      )}
                    </span>
                  </li>
                );
              })}
            </div>
          </m.ol>
        )}
      </AnimatePresence>
    </li>
  );
};

export default MobileLanguages;
