//Hooks
import { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion as m } from "framer-motion";

//Components
import BoxComposition from "./BoxComposition";
import AllergensContainer from "./AllergensContainer";
import OrderContainer from "./OrderContainer";
import Ingredients from "./Ingredients";
import SwiperMenu from "../SwiperMenu";
import Storage from "./Storage";
import ImageContainer from "./ProductItem/ImageContainer";

//CSS
import classes from "./ProductDetailsComponent.module.css";

const ProductDetailsComponent = ({ data }) => {
  const containersRef = useRef();
  const [activeImage, setActiveImage] = useState(data.imgs[0]);
  const [active, setActive] = useState(0);

  const handleSectionVisibility = (i) => {
    setActive(i);
  };

  useEffect(() => {
    setActiveImage(data.imgs[0]);
  }, [data.imgs]);

  const productInfoContainers = [
    {
      id: 0,
      class: classes.orderContainer,
      flex: "flex",
      jsx: <OrderContainer data={data} />,
    },
    {
      id: 1,
      class: classes.allergensContainer,
      flex: "grid",
      jsx: <AllergensContainer data={data} />,
    },
    {
      id: 2,
      class: classes.containerStorage,
      flex: "flex",
      jsx: <Storage data={data} />,
    },
    {
      id: 3,
      class: classes.containerStorage,
      flex: "flex",
      jsx: <Ingredients data={data} />,
    },
    {
      id: 4,
      class: classes.boxComposition,
      flex: "grid",
      jsx: <BoxComposition data={data} />,
    },
  ];

  const styles = {
    initial: {
      x: "-130%",
    },
    animate: {
      x: 0,
      transition: {
        delay: 0.4,
        duration: 0.3,
      },
    },
    exit: {
      x: "130%",
      transition: {
        duration: 0.4,
        ease: "easeIn",
        delay: 0,
      },
    },
    transition: {
      duration: 0.5,
      ease: "easeOut",
      delay: 0.2,
      bounce: 0,
    },
  };

  const swiperSlidesStyles = (id, display) => {
    return {
      display: display ? "inline-block" : "none",
      backgroundColor: id === active ? "var(--brown)" : "transparent",
      color: id === active ? "var(--sandWhite)" : "var(--brown)",
    };
  };

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <div className={classes.albumContainer}>
          <ol>
            {data.imgs.map((img, i) => (
              <li
                style={{
                  border: activeImage === img ? "1px solid var(--brown)" : null,
                }}
                key={i}
                onClick={() => setActiveImage(img)}
              >
                <img src={img} alt="album" />
              </li>
            ))}
          </ol>
        </div>
        <ImageContainer
          src={activeImage}
          alt={data.name}
          hasTag={data.tag.hasTag}
          tagColor={data.tag.color}
          tagTitle={data.tag.tagTitle}
          isVisible={true}
          initialScale={1}
          animateScale={1}
          className={classes.imgWrapper}
        />
      </div>
      <div className={classes.infoContainer}>
        <div>
          <h1>{data.name}</h1>
          <SwiperMenu
            data={data.productInfo}
            onClickFunction={handleSectionVisibility}
            slidesStyle={swiperSlidesStyles}
            sliderStyle={classes.slider}
          />
          <ul className={classes.containers}>
            <AnimatePresence initial={false}>
              {productInfoContainers.map(
                (item, i) =>
                  active === item.id && (
                    <m.li
                      ref={containersRef}
                      {...styles}
                      className={item.class}
                      key={i}
                    >
                      {item.jsx}
                    </m.li>
                  )
              )}
            </AnimatePresence>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsComponent;
