//Hooks
import { useState} from "react";
import { useTranslation } from "react-i18next";
import { setLanguageVisibility } from "../../../../store";
import { useDispatch } from "react-redux";

//Data
import { languages } from "../../../../data/languagesData";

//Icons
import { GrRadialSelected } from "react-icons/gr";
import { GrRadial } from "react-icons/gr";

//CSS
import classes from "./DropdownLanguages.module.css";

const DropdownLanguages = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [active, setActive] = useState(i18n.language);

  const handleLanguageChange = (tag) => {
    i18n.changeLanguage(tag);
    setActive(tag);
    dispatch(setLanguageVisibility(false));
  };

  return (
    <ul className={classes.ul}>
      {languages().map(({ name, flag, tag }, i) => {
        return (
          <li key={i} onClick={() => handleLanguageChange(tag)}>
            <div className={classes.container}>
              {flag}
              <span>{name}</span>
            </div>
            <span className={classes.radial}>
              {active === tag ? (
                <GrRadialSelected fontSize={"1rem"} />
              ) : (
                <GrRadial fontSize={"1rem"} />
              )}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default DropdownLanguages;
