//Hooks
import { useDispatch, useSelector } from "react-redux";
import { setCart, updateItemQuantity } from "../store/index";

const useAddItemToCart = ({
  updatedProductData,
  productData,
  boxSize,
  quantity,
  setQuantity,
  setBoxSize,
  orderData,
}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);

  return () => {
    // Check if the product is already in the cart with the same name and box size
    const existingProduct = cartItems.find(
      (item) =>
        item.name === productData.name &&
        item.pieces === boxSize.pieces &&
        item.price === boxSize.price
    );

    // If the product is already in the cart, update its quantity
    if (existingProduct) {
      dispatch(
        updateItemQuantity({
          itemName: existingProduct.name,
          itemPieces: existingProduct.pieces,
          newQuantity: existingProduct.quantity + quantity,
        })
      );
    } else {
      dispatch(setCart([...cartItems, updatedProductData]));
    }

    //close quick purchase
    setQuantity(1);
    setBoxSize({
      pieces: orderData.sizes[0].pieces,
      price: orderData.sizes[0].price,
    });
    // setQuickPurchase(false);
  };
};

export default useAddItemToCart;
