//Hooks
import { motion as m, AnimatePresence, useAnimate } from "framer-motion";
import { useState, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/use-windowDimensions";

//Components
import HeaderPhotoContainer from "./HeaderPhotoContainer";
import SocialMediaIconsMobile from "../../../ui/SocialMediaIconsMobile";
import SearchBoxMobile from "../../../ui/Search/SearchBoxMobile";
import Menu from "./Menu";
import RecentlySearched from "../../../ui/Search/RecentlySearched";

//CSS
import classes from "./DropdownMenu.module.css";

const DropdownMenu = memo(function DropdownMenu(props) {
  const [scope, animate] = useAnimate();
  const size = useWindowSize();

  const isMobileSearchActive = useSelector(
    (state) => state.isSearchInputActive.mobile
  );

  const [photo, setPhoto] = useState({});

  const photoMenuToShowHandler = ({ photo, name, path }) => {
    if (size.width < 1100) {
      return;
    }
    setPhoto({ photo: photo, name: name, path: path });
  };

  /*-------------------------------------------- bar disappearance on search animation --------------------------------------------*/

  const barStyles = {
    initial: false,
    animate: {
      left: "50%",
      top: "0.7rem",
      opacity: !isMobileSearchActive ? 1 : 0,
      transform: "translateX(-50%)",
    },
    transition: { duration: 0.2, delay: 0.3, bounce: 0 },
  };

  /*-------------------------------------------- menu to full height animation --------------------------------------------*/

  useEffect(() => {
    if (isMobileSearchActive) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          {
            height: "100svh",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: "0px solid var(--borderSandWhite)",
          },
          { duration: 0.4, delay: 0, bounce: 0 }
        );
      };

      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          {
            height: "100%",
            borderTopLeftRadius: props.borderRadius,
            borderTopRightRadius: props.borderRadius,
            borderTop: "1px solid var(--borderSandWhite)",
          },
          { duration: 0.4, delay: 0, bounce: 0 }
        );
      };

      exitAnimation();
    }
  }, [isMobileSearchActive, scope, props, animate]);

  const animationsStyle = {
    animate: { opacity: isMobileSearchActive ? 0 : 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, delay: 0 },
  };

  return (
    <m.div
      ref={scope}
      style={props.gestures()}
      {...props.animations}
      onMouseLeave={props.onLeave}
      className={`${classes.header_dropdown} requires-no-scroll`}
    >
      {size.width < 640 && <m.div {...barStyles} className="bar"></m.div>}
      {size.width < 1100 && <SearchBoxMobile handlers={props.handlers} />}
      <m.div {...animationsStyle} className={classes.header_list}>
        <Menu
          photoMenuToShowHandler={photoMenuToShowHandler}
          setPhoto={setPhoto}
        />
      </m.div>
      <AnimatePresence>
        {isMobileSearchActive && <RecentlySearched size={size} />}
      </AnimatePresence>
      {size.width < 1100 && size.width > 640 && <SocialMediaIconsMobile />}
      {size.width > 1100 && (
        <HeaderPhotoContainer photo={photo.photo} name={photo.name} />
      )}
    </m.div>
  );
});

export default DropdownMenu;
