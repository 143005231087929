//Hooks
import Swiper from "../../../hooks/use-swiper";
import { SwiperSlide } from "swiper/react";
import useWindowSize from "../../../hooks/use-windowDimensions";

//Components
import ProductItem from "../../ui/Product/ProductItem/ProductItem";

//CSS
import classes from "./ProductSwiper.module.css";

const CupcakeSwiper = ({ data, swiperStyle, slidesStyle }) => {
  const size = useWindowSize();
  const spaceBetween = size.width < 640 ? 10 : 20;

  return (
    <Swiper
      spaceBetween={spaceBetween}
      pagination={{ dynamicBullets: data.length > 3 ? true : false }}
      loop={false}
      breakpoints={{
        0: {
          slidesPerView: 1.1,
        },
        640: {
          slidesPerView: "auto",
        },
      }}
      swiperClass={`${classes.productSwiper} ${swiperStyle}`}
    >
      {data.map((data, i) => {
        return (
          <SwiperSlide
            className={`${classes.productSlides} ${slidesStyle}`}
            key={i}
          >
            <ProductItem prod={data} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CupcakeSwiper;
