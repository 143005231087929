//Hooks
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { useTranslation } from "react-i18next";
import { useInput } from "../../../hooks/use-input";
import { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { useDispatch } from "react-redux";
import { setCart } from "../../../store/index.js";
import { isEmail, isNotEmpty, hasMinLength } from "../../../util/validation.js";
import {
  isNotification,
  notificationMessage,
  notificationColor,
  notificationIcon,
} from "../../../store/index.js";

//Components
import Input from "../Input";
import OrderSummary from "./OrderSummary";
import Button from "../Button";

//Icons
import { IoChevronBackOutline } from "react-icons/io5";

//CSS
import classes from "./ShoppingCartForm.module.css";

//Data
import { dates } from "../../../data/unavailableOrderDates/dates.js";

const orderingDateInfo =
  "In an effort to enhance our service and ensure timely processing, we kindly request that all orders be placed at least one day prior to your desired pickup date.";

const ShoppingCartForm = ({
  generateOrderId,
  extractedData,
  totalPrice,
  goToPrevSlide,
}) => {
  const today = dayjs();
  const unavailableDates = dates.map((date) => dayjs(date));

  // Add all Sundays as unavailable dates
  for (let i = 0; i < 365; i++) {
    const date = today.clone().add(i, "day");
    if (date.day() === 0) {
      // Sunday is represented as 0
      unavailableDates.push(date);
    }
  }

  const disabledDates = [today, ...unavailableDates];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(null);

  const [selectedDate, setSelectedDate] = useState(today);
  const formRef = useRef();

  const {
    value: nameValue,
    handleInputChange: handleNameChange,
    handleInputBlur: handleNameBlur,
    hasError: nameHasError,
    valueHasError: nameValueHasError,
    setValue: setNameValue,
    setEdit: setNameEdit,
  } = useInput("", (value) => isNotEmpty(value));

  const {
    value: emailValue,
    handleInputChange: handleEmailChange,
    handleInputBlur: handleEmailBlur,
    hasError: emailHasError,
    valueHasError: emailValueHasError,
    setValue: setEmailValue,
    setEdit: setEmailEdit,
  } = useInput("", (value) => isEmail(value) && isNotEmpty(value));

  const {
    value: phoneValue,
    handleInputChange: handlePhoneChange,
    handleInputBlur: handlePhoneBlur,
    hasError: phoneHasError,
    valueHasError: phoneValueHasError,
    setValue: setPhoneValue,
    setEdit: setPhoneEdit,
  } = useInput("", (value) => isNotEmpty(value) && hasMinLength(value, 9));

  const {
    value: messageValue,
    handleInputChange: handleMessageChange,
    handleInputBlur: handleMessageBlur,
    setValue: setMessageValue,
    setEdit: setMessageEdit,
  } = useInput("", (value) => isNotEmpty(value));

  const isDisabled =
    !nameValueHasError ||
    !emailValueHasError ||
    !phoneValueHasError ||
    selectedDate == null;

  const resetFormFields = () => {
    setNameValue("");
    setEmailValue("");
    setPhoneValue("");
    setMessageValue("");
    setNameEdit(false);
    setEmailEdit(false);
    setPhoneEdit(false);
    setMessageEdit(false);
  };

  useEffect(() => {
    const nextAvailableDate = findNextAvailableDate(today);
    setSelectedDate(nextAvailableDate);
  }, []);

  const dispatchNotification = (isTriggered, message, color, icon) => {
    dispatch(isNotification(isTriggered));
    dispatch(notificationMessage(message));
    dispatch(notificationColor(color));
    dispatch(notificationIcon(icon));

    setTimeout(() => {
      dispatch(isNotification(false));
    }, 5000);
  };

  const handleDateChange = (date) => {
    if (isDateDisabled(date)) {
      dispatchNotification(
        true,
        `${date.format("DD-MM-YYYY")} ${t("notifications.unavailable_date")}`,
        "red",
        "error"
      );
      return;
    }
    dispatchNotification(false);
    setSelectedDate(date);
  };

  const findNextAvailableDate = (date) => {
    let nextDate = date.clone().add(1, "day");
    while (isDateDisabled(nextDate)) {
      nextDate = nextDate.add(1, "day");
    }
    return nextDate;
  };

  const isDateDisabled = (date) => {
    return disabledDates.some((disabledDate) =>
      dayjs(disabledDate).isSame(date, "day")
    );
  };

  const renderDay = (date, _value) => {
    const isDisabled = isDateDisabled(date);

    return (
      <DateCalendar.Day {..._value} disabled={isDisabled}>
        {date.format("D")}
      </DateCalendar.Day>
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();

    setIsButtonDisabled(true);
    setIsLoading(true);

    if (isDisabled) {
      dispatchNotification(
        true,
        t("notifications.incomplete order information"),
        "red",
        "error"
      );
      setIsButtonDisabled(false);
      setIsLoading(false)
      return;
    }

    emailjs
      .send(
        "service_xbehl6h",
        "template_opocmzx",
        {
          orderId: "#" + generateOrderId(6),
          user_name: nameValue,
          user_email: emailValue,
          user_phone: phoneValue,
          user_message: messageValue,
          user_selectedDate: selectedDate.format("DD-MM-YYYY"),
          products: extractedData,
          totalPrice: totalPrice,
        },
        "_qKrXEZhNIK_Gl97P"
      )
      .then(
        () => {
          dispatchNotification(
            true,
            t("notifications.order sent successfully"),
            "green",
            "success"
          );

          setIsLoading(false);
          resetFormFields();

          setTimeout(() => {
            dispatch(setCart([]));
            setIsButtonDisabled(false)
          }, 5400);
        },
        () => {
          setIsLoading(false);
          dispatchNotification(
            true,
            t("notifications.order not sent"),
            "red",
            "error"
          );

          setTimeout(() => {
            dispatch(setCart([]));
            setIsButtonDisabled(false)
          }, 5000);
        }
      );
  };

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    // Convert highlighted dates to an array of strings in the format "YYYY-MM-DD"
    const highlightedDates = disabledDates.map((date) =>
      date.format("YYYY-MM-DD")
    );

    // Check if the current day is in the list of highlighted dates for the current month
    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDates.includes(day.format("YYYY-MM-DD"));

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? "🚫" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  return (
    <div className={classes.orderContainer}>
      <div className={classes.userInformation}>
        <h2>{t("other.personal info")}</h2>
        <form
          ref={formRef}
          onSubmit={sendEmail}
          className={classes.form}
          autoComplete="on"
        >
          <Input
            className={classes.name}
            label={t("forms.first_name")}
            id="name"
            name="name"
            type="text"
            onBlur={handleNameBlur}
            onChange={handleNameChange}
            value={nameValue}
            error={nameHasError && t("forms.error_name")}
          />
          <span className={classes.duo}>
            <Input
              className=""
              label={t("forms.email")}
              id="email"
              name="email"
              type="email"
              onBlur={handleEmailBlur}
              onChange={handleEmailChange}
              value={emailValue}
              error={emailHasError && t("forms.error_surname")}
            />
            <Input
              className={classes.phone}
              label={t("forms.phone")}
              id="phone"
              name="phone"
              type="text"
              onBlur={handlePhoneBlur}
              onChange={handlePhoneChange}
              value={phoneValue}
              error={phoneHasError && t("forms.error_phone")}
            />
          </span>
          <div className={classes.calendarContainer}>
            <p>{t("forms.select_date")}</p>
            <div className={classes.calendarWrapper}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  className={classes.calendar}
                  value={selectedDate} // Pass selectedDate as the value
                  onChange={handleDateChange}
                  components={{ Day: renderDay }}
                  slots={{ day: ServerDay }}
                  slotProps={{
                    day: {
                      highlightedDays: disabledDates.filter(
                        (date) =>
                          date.isSame(selectedDate, "month") &&
                          date.isSame(selectedDate, "year")
                      ),
                    },
                  }}
                />
              </LocalizationProvider>
              <div className={classes.orderingDateInfo}>
                <p>{orderingDateInfo}</p>
              </div>
            </div>
          </div>
          <span className={classes.message}>
            <label>{t("forms.additional_information")}</label>
            <textarea
              name="message"
              onBlur={handleMessageBlur}
              onChange={handleMessageChange}
              value={messageValue}
            />
          </span>
        </form>
      </div>
      <OrderSummary
        totalPrice={totalPrice}
        callToAction={sendEmail}
        buttonName={t("buttons.order")}
        triggerAnimation={isLoading}
        disable={isButtonDisabled}
      >
        <Button
          buttonName={<IoChevronBackOutline fontSize={"1.8rem"} />}
          callToAction={goToPrevSlide}
        />
      </OrderSummary>
    </div>
  );
};

export default ShoppingCartForm;
