//CSS
import classes from "./OriginOfOurEng.module.css";

//Photos
import photo from "../../../assets/images/other/Baking-Pantry-IG-3.png";

const OriginOfOurEng = () => {
  return (
    <div className={`${classes.container} containerStyle`}>
      <div>
        <div className={classes.paraContainer}>
          <p>
            Welcome to our delectable world of artisanal pastries at Sienna
            pastry boutique! At the heart of our culinary creations lies a
            commitment to using only the finest, high-quality ingredients. We
            take pride in crafting exquisite handmade cookies, chocolates,
            cupcakes, and more that are not only a treat for your taste buds but
            also a celebration of the pure flavors nature has to offer.
          </p>
          <p>
            In every delightful bite, you'll savor the authenticity of our
            pastries, as we meticulously source and incorporate natural
            ingredients. We believe in the power of simplicity, and that's why
            our recipes are free from preservatives. We want you to experience
            the true essence of each ingredient, allowing the flavors to shine
            through and create an unforgettable gastronomic experience.
          </p>
          <p>
            Our commitment to using natural ingredients is a testament to our
            dedication to providing you with a wholesome indulgence. From the
            rich cocoa in our chocolates to the buttery goodness in our cookies,
            every component is thoughtfully chosen to ensure that each creation
            reflects the passion and craftsmanship of our talented bakers.
          </p>
          <p>
            At Sienna pastry boutique, we invite you to explore our ingredients
            section, where transparency meets taste. Discover the stories behind
            the luscious fruits, premium chocolates, and other elements that go
            into making our pastries a true labor of love. Join us on a journey
            where quality meets creativity, and indulge in treats that not only
            satisfy your sweet cravings but also leave you with a sense of
            satisfaction, knowing that you're enjoying pure, natural goodness.
          </p>
        </div>
        <div className={classes.photoContainer}>
          <img src={photo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default OriginOfOurEng;
