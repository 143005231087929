export const faqData = [
  {
    id: 0,
    name: "orders",
    topics: [
      {
        id: 0,
        header: "How do I place an order?",
        text: [
          {
            para: "You can order our products from this site by clicking on the tab Online shop. After having chosen your shipping mode and products, you must add them to your shopping cart. You then need to log into your account by entering your email address and password. If you haven't got an account yet, you need to fill out the registration form. Enter your delivery address and billing address. If you would like to do so, you can join a personal message to your order. You must then accept our general terms and conditions of sale before proceeding to secured payment. Once your order is accepted and the online payment is made, a confirmation email is sent to you. It contains your order number.",
          },
        ],
      },
      {
        id: 1,
        header: "Can I choose my own assortment?",
        text: [
          {
            para: "You can arrange your own box. You must Clear the box, then choose your wanted flavours by clicking on them. The chosen flavour will automatically be registered in the box.",
          },
        ],
      },
      {
        id: 2,
        header: "How can I add a message to my order?",
        text: [
          {
            para: "Siena offers you the possibility to add a personnal message to your order for free. This option is available after the summary of your shopping cart.",
          },
        ],
      },
      {
        id: 3,
        header: "I have not received an order confirmation email.",
        text: [
          {
            para: "If you haven't received a confirmation email, then it was maybe placed in the spam section of your electronic mailbox. If the problem persists, do not hesitate to contact us using our contact form. You can also check your order status.",
          },
        ],
      },
      {
        id: 4,
        header: "Can I change my order content?",
        text: [
          {
            para: "As long as the payment is not completely registered, you can change your order content by accessing your shopping cart. Once the payment is registered and accepted, you will not be able to change your order, except for a similar article at the same price (for example, it is possible to replace a box of rose tea by a box of caramel tea), by using our contact form. Your change request must reach us at least 48 hours before the scheduled delivery date. You can not add articles to an order previously accepted and registered.",
          },
        ],
      },
      {
        id: 5,
        header: "Can I cancel my order?",
        text: [
          {
            para: "You can cancel your order only by using our contact form. Your request must reach us at least 48 hours before the scheduled delivery date.",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    name: "payment",
    topics: [
      {
        id: 6,
        header: "Which online payment options are available?",
        text: [
          {
            para: "Only payments by credit card are accepted for an online payment. We accept payments by Visa, Blue Card, Mastercard and American Express. Ladurée gift vouchers can not be used for online payments.",
          },
        ],
      },
      {
        id: 7,
        header: "Is payment secure?",
        text: [
          {
            para: "The payments made on our website and the information related to your order are secured. This information is crypted and protected by our online payment service.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "delivery",
    topics: [],
  },
  {
    id: 3,
    name: "product_information",
    topics: [],
  },
  {
    id: 4,
    name: "others",
    topics: [],
  },
];
