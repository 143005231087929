//Hooks
import { useTranslation } from "react-i18next";

const CartItemHeader = () => {
  const { t } = useTranslation();

  return (
    <li>
      <div>
        <p>{t("other.product details")}</p>
      </div>
      <div></div>
      <div>
        <p>{t("other.quantity")}</p>
      </div>
      <div>
        <p>{t("other.unit price")}</p>
      </div>
      <div>
        <p>{t("other.total")}</p>
      </div>
      <div style={{width: "39.47px"}}></div>
    </li>
  );
};

export default CartItemHeader;
