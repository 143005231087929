//Hooks
import MetaTags from "../../hooks/use-metaTags";
import { useTranslation } from "react-i18next";

//Components
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ElementWrapper from "../layout/main/ElementWrapper";
import BackgroundSweets from "../layout/main/BackgroundSweets";
import Guarantee from "../layout/main/Guarantee";
import AboutUs from "../layout/main/AboutUs";
import LocationMap from "../layout/main/LocationMap";
import LocationPathGuide from "../layout/main/LocationPathGuide";

//Data
import { aboutTags } from "../../data/metaTags/aboutTags";

const About = () => {
  const {t} = useTranslation()
  return (
    <>
      <MetaTags tags={aboutTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide />
        <BackgroundSweets pageName={t("pages.our_story")} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="aboutUs">
        <AboutUs />
        <Guarantee />
      </ElementWrapper>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <LocationMap />
      </ElementWrapperFullWidth>
    </>
  );
};

export default About;
