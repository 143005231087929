//Hooks
import { Link } from "react-router-dom";
import Swiper from "../../../hooks/use-swiper";
import { SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/use-windowDimensions";

//Data
import { products } from "../../../data/products/all-products/all-products";

//Img
import cupcakeBouquet from "../../../assets/images/swiperNavigation/cupcakeBouquet.png";

//CSS
import classes from "./RouteSwiper.module.css";

const allProducts = {
  id: "0",
  name: "all products",
  path: "all-the-products",
  pathImg: cupcakeBouquet,
  hasSubMenu: false,
  img: "https://i.postimg.cc/d0Q5rS1h/all-Products.jpg",
};

const routesArr = [
  allProducts,
  ...products,
  allProducts,
  ...products,
  allProducts,
  ...products,
];

const RouteSwiper = () => {
  const size = useWindowSize();
  const { t } = useTranslation();

  let routeSwiperSlidesPerView;

  if (size.width < 640) {
    routeSwiperSlidesPerView = 3;
  } else if (size.width > 640 && size.width < 1200) {
    routeSwiperSlidesPerView = 5;
  } else if (size.width > 1200 && size.width < 1800) {
    routeSwiperSlidesPerView = 7;
  } else {
    routeSwiperSlidesPerView = 9;
  }

  return (
    <Swiper
      spaceBetween={10}
      pagination={{ dynamicBullets: true }}
      loop={true}
      slidesPerView={routeSwiperSlidesPerView}
      swiperClass={classes.routeSwiper}
    >
      {routesArr.map(({ pathImg, name, path }, i) => {
        return (
          <SwiperSlide className={classes.routeSlides} key={i}>
            <Link to={path}>
              <div className={classes.routeImgContainer}>
                <img src={pathImg} alt={name} />
              </div>
              <div className={classes.routeInfo}>
                <div>
                  <span>{t(`product_categories.${name}`)}</span>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default RouteSwiper;
