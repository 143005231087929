//Hooks
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputValue,
  clearInputValue,
  setSuggestions,
  setSearchResults,
  setSearchValue,
  setDesktopSearch,
  setMobileSearch,
  setMenuVisibility,
} from "../store/index";
import { isNotEmpty } from "../util/validation";

const useEnterKeyPress = (dropdownMenu) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputValue = useSelector((state) => state.input.value);

  const updateInputAndSearch = (value) => {
    dispatch(setInputValue(value));
    dispatch(setSearchValue(value));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    updateInputAndSearch(value);

    // Filter products based on input value
    const filteredSuggestions = value
      ? dropdownMenu.filter((product) =>
          product.toLowerCase().includes(value.toLowerCase().replace(/ /g, "-"))
        )
      : [];

    dispatch(setSuggestions(filteredSuggestions));
    dispatch(setSearchResults(filteredSuggestions));
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    updateInputAndSearch(value);

    if (e.key === "Enter" && isNotEmpty(value)) {
      navigate("search-results-products");
      dispatch(clearInputValue());
      dispatch(setSuggestions([]));
      dispatch(setDesktopSearch(false));
      dispatch(setMobileSearch(false));
      dispatch(setMenuVisibility(false));
    }
  };

  return { inputValue, handleInputChange, handleKeyDown };
};

export default useEnterKeyPress;
