//Icons
import packaging from "../assets/icons/packaging.png";
import guarantee from "../assets/icons/high-quality.png";
import naturalIngredient from "../assets/icons/plant-based.png";

export const guaranteeData = [
  {
    icon: packaging,
    header: "protected_products",
    text: "text_protected_products",
  },
  {
    icon: guarantee,
    header: "quality_guaranteed",
    text: "text_quality_guaranteed",
  },
  {
    icon: naturalIngredient,
    header: "natural_ingredients",
    text: "text_natural_ingredients",
  }
];
