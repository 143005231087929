//Hooks
import { useState } from "react";
import { IconContext } from "react-icons";
import { motion as m, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

//Icons
import { GiSettingsKnobs } from "react-icons/gi";
import { GrRadialSelected } from "react-icons/gr";
import { GrRadial } from "react-icons/gr";
import { PiSortAscendingThin } from "react-icons/pi";
import { PiSortDescendingThin } from "react-icons/pi";
import { PiArrowsDownUpThin } from "react-icons/pi";

//CSS
import classes from "./Sort.module.css";

const DUMMY_DATA = [
  {
    name: "default",
    id: 0,
    icon: (
      <PiArrowsDownUpThin
        fontSize={"1.7rem"}
        color={"var(--brown)"}
        style={{ height: "1.6rem" }}
      />
    ),
  },
  {
    name: "price_low_to_high",
    id: 1,
    icon: <PiSortDescendingThin fontSize={"1.7rem"} color={"var(--brown)"} />,
  },
  {
    name: "price_heigh_to_low",
    id: 2,
    icon: <PiSortAscendingThin fontSize={"1.7rem"} color={"var(--brown)"} />,
  },
  {
    name: "letter_a_to_z",
    id: 3,
    icon: <PiSortDescendingThin fontSize={"1.7rem"} color={"var(--brown)"} />,
  },
  {
    name: "letter_z_to_a",
    id: 4,
    icon: <PiSortAscendingThin fontSize={"1.7rem"} color={"var(--brown)"} />,
  },
];

const Sort = ({ setData, data, defaultData }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(0);
  const [activeMenuName, setActiveMenuName] = useState("default")

  const handleClickedMenuItem = (id, name) => {
    setActive(id);
    setActiveMenuName(name)
    if (id === 0) {
      setData(defaultData);
    } else if (id === 1) {
      handleSortAscending();
    } else if (id === 2) {
      handleSortDescending();
    } else if (id === 3) {
      handleLetterSortAscending();
    } else if (id === 4) {
      handleLetterSortDescending();
    }
    setShow(false);
  };

  const handleSortAscending = () => {
    const sortedSubMenu = data.slice().sort((a, b) => {
      const priceA = parseInt(a.productInfo[0].sizes[0].price, 10);
      const priceB = parseInt(b.productInfo[0].sizes[0].price, 10);
      return priceA - priceB;
    });
    setData(sortedSubMenu);
  };

  const handleSortDescending = () => {
    const sortedSubMenu = data.slice().sort((a, b) => {
      const priceA = parseInt(a.productInfo[0].sizes[0].price, 10);
      const priceB = parseInt(b.productInfo[0].sizes[0].price, 10);
      return priceB - priceA;
    });
    setData(sortedSubMenu);
  };

  const handleLetterSortAscending = () => {
    const sorted = [...data].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameA.localeCompare(nameB);
    });
    setData(sorted);
  };

  const handleLetterSortDescending = () => {
    const sorted = [...data].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameB.localeCompare(nameA);
    });
    setData(sorted);
  };

  const suggestionsStyles = {
    initial: { height: 0, opacity: 0 },
    animate: { height: "auto", opacity: 1 },
    exit: {
      height: 0,
      opacity: 0,
      transition: { opacity: { duration: 0.2, delay: 0.1 } },
    },
    transition: { duration: 0.3, delay: 0 },
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <div className={classes.container}>
        <div>
          <div className={classes.categoryContainer}>
            <span>{data.length}</span>
            <h6>{t("other.results")}</h6>
          </div>
          <div className={classes.dropdownContainer}>
            <div onClick={() => setShow((prev) => !prev)}>
              <span>{t(`sort.${activeMenuName}`)}</span>
              <span>
                <GiSettingsKnobs
                  className={show ? classes.downArrow : classes.upArrow}
                  fontSize={"1.7rem"}
                  color={"var(--brown)"}
                />
              </span>
            </div>
            <AnimatePresence>
              {show && (
                <m.div {...suggestionsStyles}>
                  <ul>
                    {DUMMY_DATA.map(({ name, id, icon }) => (
                      <li key={id} onClick={() => handleClickedMenuItem(id, name)}>
                        <span>{icon}</span>
                        <p>{t(`sort.${name}`)}</p>
                        <span className={classes.radial}>
                          {active === id ? (
                            <GrRadialSelected fontSize={"1rem"} />
                          ) : (
                            <GrRadial fontSize={"1rem"} />
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                </m.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Sort;
