const AllergensContainer = ({ data }) => {
  const allergensData = data.productInfo[1].allergens;

  return (
    <ol>
      {allergensData.map((allergen, i) => (
        <li key={i}>
          <img src={allergen.img} alt={allergen.title} />
          <p>{allergen.title}</p>
        </li>
      ))}
    </ol>
  );
};

export default AllergensContainer;
