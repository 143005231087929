//Hooks
import { motion as m, AnimatePresence } from "framer-motion";
import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import usePagination from "../../../hooks/use-pagination";
import { useTranslation } from "react-i18next";

//Components
import ProductItem from "./ProductItem/ProductItem";
import Error from "../../layout/main/Error";
import Sort from "../Sort";
import ElementWrapper from "../../layout/main/ElementWrapper";
import PaginationButtons from "../PaginationButtons";
import ComponentSplitter from "../../layout/main/ComponentSplitter";
import useWindowSize from "../../../hooks/use-windowDimensions";

//Data
import { products } from "../../../data/products/all-products/all-products";

//CSS
import classes from "./SingleProductCategory.module.css";

const SingleProduct = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const params = useParams();
  const category = params.category;

  const replaceDashWithSpace = useMemo(() => {
    return (input) => input.replace(/-/g, " ");
  }, []);

  const errorMessage = `Oops! We couldn't find any products in the ${category} category.`;

  function findProductByPath(productArr, targetPath) {
    return productArr.find(
      (product) => product.path.toLowerCase() === targetPath.toLowerCase()
    );
  }

  const data = findProductByPath(products, `all-the-products/${category}`);

  const [categoryArr, setCategoryArr] = useState(data ? data.subMenu : []);
  const [defaultData, setDefaultData] = useState(data ? data.subMenu : []);

  useEffect(() => {
    setCategoryArr(data ? data.subMenu : []);
    setDefaultData(data ? data.subMenu : []);
  }, [data]);

  const itemsPerPage = 9; // Number of items per page
  const totalProducts = categoryArr.length;
  const scrollToTopOffset = size.width < 640 ? 0 : 0;

  const {
    currentPage,
    nextPage,
    prevPage,
    totalPages,
    startIndex,
    endIndex,
    setCurrentPage,
  } = usePagination(totalProducts, itemsPerPage, category, scrollToTopOffset);

  const liStyle = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  return (
    <ElementWrapper>
      {categoryArr.length > 0 ? (
        <>
          <ComponentSplitter
            subtitle={`All of our ${replaceDashWithSpace(category)}`}
            title="Click & discover"
            className={classes.splitter}
          />
          <Sort
            setData={setCategoryArr}
            data={categoryArr}
            defaultData={defaultData}
          />
          <div className={classes.container}>
            <div className={classes.productContainer}>
              <ul className={classes.gridContainer}>
                <AnimatePresence>
                  {categoryArr.slice(startIndex, endIndex).map((prod, id) => (
                    <m.li {...liStyle} key={id}>
                      <ProductItem prod={prod} currentPage={currentPage} />
                    </m.li>
                  ))}
                </AnimatePresence>
              </ul>
              <PaginationButtons
                prevPage={prevPage}
                nextPage={nextPage}
                currentPage={currentPage}
                totalPages={totalPages}
                totalProducts={totalProducts}
                setCurrentPage={setCurrentPage}
                top={scrollToTopOffset}
              />
            </div>
          </div>
        </>
      ) : (
        <Error
          message={
            t("notifications.product404_4") +
            " " +
            category +
            " " +
            t("notifications.product404_5")
          }
        />
      )}
    </ElementWrapper>
  );
};

export default SingleProduct;
