//Hooks
import { motion as m } from "framer-motion";
import { useSelector } from "react-redux";
import { IconContext } from "react-icons";

//CSS
import classes from "./Notification.module.css";

//Icons
import { IoAlertOutline, IoCheckmarkOutline } from "react-icons/io5";

export default function Notification({ animation }) {
  const notification = useSelector((state) => state.isNotification);
  const iconStyles = { fontSize: "1.7rem", color: "var(--sandWhite)" };

  const iconStyle =
    notification.icon === "error" ? (
      <IoAlertOutline style={iconStyles} />
    ) : (
      <IoCheckmarkOutline style={iconStyles} />
    );

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <m.div
        className={classes.container}
        {...animation}
        style={{ backgroundColor: notification.color }}
      >
        <span>
          <div className={classes.iconWrapper}>
            <span>{iconStyle}</span>
            <span
              style={{ backgroundColor: notification.color }}
              className={classes.backgroundColor}
            ></span>
          </div>
          <h4>{notification.message}</h4>
        </span>
        <div className={classes.countdownLine}></div>
      </m.div>
    </IconContext.Provider>
  );
}
