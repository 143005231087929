//Hooks
import { useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y } from "swiper";

//CSS
import classes from "./ProductDetailsComponent.module.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

//Icons
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const BoxComposition = ({ data }) => {
  const boxComposition = data.productInfo[4];

  const [id, setId] = useState({});

  const handleDetails = (id) => {
    setId((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const animateStyle = {
    initial: { y: "100%" },
    animate: { y: 0 },
    exit: { y: "100%" },
    transition: { bounce: false, duration: 0.3, delay: 0 },
  };

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView="auto"
      scrollbar={{ draggable: true }}
      modules={[Scrollbar, A11y]}
      className={classes.swiperBoxComposition}
    >
      {boxComposition.boxItems.map((item, i) => (
        <SwiperSlide key={i} className={classes.slideBoxComposition}>
          <img src={item.img} alt={item.title} />
          <div>
            <h3>{item.name}</h3>
            <p>X{item.quantity}</p>
          </div>
          <div className={classes.showDetailsButtonContainer}>
            <span onClick={() => handleDetails(i)}>
              <ArrowDropUpIcon />
            </span>
            <p>Show details</p>
          </div>
          <AnimatePresence>
            {id[i] && (
              <m.span {...animateStyle} className={classes.showMoreContainer}>
                <div>
                  <span
                    onClick={() => handleDetails(i)}
                    className={classes.hideDetailsButton}
                  >
                    <ArrowDropUpIcon />
                  </span>
                  <p>Hide details</p>
                </div>
                <div>
                  <p>Allergens</p>
                  <div className={classes.showMoreContainerList}>
                    <p>{item.allergens}</p>
                  </div>
                </div>
              </m.span>
            )}
          </AnimatePresence>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BoxComposition;
