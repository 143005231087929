//Hooks
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { clearInputValue } from "../../../../store/index";
import useEnterKeyPress from "../../../../hooks/use-enterKeyPress";
import { isNotEmpty } from "../../../../util/validation";
import { useTranslation } from "react-i18next";

//Components
import SearchInput from "../SearchInput";
import SearchResultsCloseButton from "./SearchResultsCloseButton";
import SearchResultsSuggestions from "./SearchResultsSuggestions";
import SearchIconButton from "../../SearchIconButton";
import ComponentSplitter from "../../../layout/main/ComponentSplitter";

//Data
import { products } from "../../../../data/products/all-products/all-products";

//CSS
import classes from "./SearchResultsHeader.module.css";

const SearchResultsHeader = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();

  const arrayOfSubMenuPaths = products.flatMap((item) =>
    item.subMenu.map((menu) => menu.searchQueryPath)
  );

  const { inputValue, handleInputChange } =
    useEnterKeyPress(arrayOfSubMenuPaths);

  const handleCloseSearch = () => {
    dispatch(clearInputValue());
    setSearch(false);
  };

  const handleOnFocus = () => {
    setSearch(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // dispatch(clearInputValue());
      setSearch(false);
    }
  };

  return (
    <div className={classes.container}>
      <ComponentSplitter
        subtitle={t("component_splitter.subtitle_search_results_products")}
        title={t("component_splitter.title_click")}
        className={classes.splitter}
      />
      <div className={classes.searchContainer}>
        <SearchInput
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          handleBlur={handleCloseSearch}
          handleFocus={handleOnFocus}
        />
        <SearchIconButton className={classes.searchIcon} />
        <AnimatePresence>
          {isNotEmpty(inputValue) && (
            <SearchResultsCloseButton handleCloseSearch={handleCloseSearch} />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {search && isNotEmpty(inputValue) && (
            <SearchResultsSuggestions setSearch={setSearch} />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SearchResultsHeader;
