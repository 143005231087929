//Hooks
import MetaTags from "../../hooks/use-metaTags";
import { useTranslation } from "react-i18next";

//Components
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ElementWrapper from "../layout/main/ElementWrapper";
import BackgroundSweets from "../layout/main/BackgroundSweets";
import Guarantee from "../layout/main/Guarantee";
import ContactUs from "../layout/main/ContactUs";
import LocationMap from "../layout/main/LocationMap";
import LocationPathGuide from "../layout/main/LocationPathGuide";

//Data
import { contactTags } from "../../data/metaTags/contactTags";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTags tags={contactTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide />
        <BackgroundSweets pageName={t("pages.contact_us")} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="contactUs">
        <ContactUs />
        <Guarantee />
      </ElementWrapper>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <LocationMap />
      </ElementWrapperFullWidth>
    </>
  );
};

export default Contact;
