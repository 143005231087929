//CSS
import classes from "./ElementWrapper.module.css";

const ElementWrapper = (props) => {
  return (
    <div
      className={`${classes.wrapper} ${props.class}`}
      style={{ backgroundColor: props.backgroundColor }}
    >
      {props.children}
    </div>
  );
};

export default ElementWrapper;
