//Hooks
import { useDispatch } from "react-redux";
import { isOverlayClicked, clearInputValue } from "../../../store/index";
import { motion as m } from "framer-motion";

//CSS
import classes from "./Overlay.module.css";

const Overlay = () => {
  const dispatch = useDispatch();

  const handleOverlayClick = () => {
    dispatch(isOverlayClicked(true));
    dispatch(clearInputValue())

    setTimeout(() => {
      dispatch(isOverlayClicked(false));
    }, 100);
  };

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: "100%" }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4, delay: 0.2 }}
      className={classes.overlay}
      onClick={handleOverlayClick}
    ></m.div>
  );
};

export default Overlay;
