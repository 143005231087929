//Hooks
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

//CSS
import classes from "./OrderSummery.module.css";

//IconsAnimation
import loadingAnimation from "../../../assets/anim/Animation - loading.json";

//Components
import Button from "../Button";
import IconAnimation from "../iconAnimations/IconAnimation";

const OrderSummary = ({
  totalPrice,
  buttonName,
  callToAction,
  children,
  triggerAnimation,
  disable,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.orderSummary}>
      <h2>{t("other.order summary")}</h2>
      <div className={classes.orderSummaryWrapper}>
        <div className={classes.totalCost}>
          <span>{t("other.estimated total")}</span>
          <span>
            {totalPrice} {t("other.currency")}
          </span>
        </div>
        <div className={classes.buttonWrapper}>
          {children && <span className={classes.backButton}>{children}</span>}
          <Button
            buttonName={buttonName}
            callToAction={callToAction}
            triggerAnimation={triggerAnimation}
            disable={disable}
          >
            <IconAnimation>
              <Lottie animationData={loadingAnimation} />
            </IconAnimation>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
