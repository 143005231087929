export const WhiteChocolateMintCupcake = {
  name: "White chocolate mint cupcake",
  imgs: [
    "https://i.postimg.cc/VNTNm8j8/products-1.jpg",
    "https://i.postimg.cc/FHMbNxZw/Lemon-Tartlets.jpg",
    "https://i.postimg.cc/sD2S1Ksz/flan-A-1800x1800.webp",
    "https://i.postimg.cc/g2XZHJxB/JT-Chocolate-Chip-Cookies-master768.jpg",
  ],
  path: "cupcakes/white-chocolate-mint-cupcake",
  searchQueryPath: "all-the-products/cupcakes/white-chocolate-mint-cupcake",
  tag: {
    tagTitle: "limited-time",
    hasTag: true,
    color: "var(--lightGreen)",
  },
  available: true,
  deliveryModel: "order & collect",
  productInfo: [
    {
      title: "order",
      display: true,
      available: true,
      description:
        "Crispy and gourmet Dacquoise cake with hazelnuts, praline and milk chocolate topped with milk chocolate whipped cream.",
      deliveryModel: "order & collect",
      sizes: [{ pieces: 6, price: "8" }],
    },
    {
      title: "allergens",
      display: true,
      allergens: [
        {
          title: "almond",
          img: "https://i.postimg.cc/Hs6W3whN/almond.png",
        },
        {
          title: "hazelnut",
          img: "https://i.postimg.cc/vTjqHSmj/hazelnut.png",
        },
        {
          title: "soya",
          img: "https://i.postimg.cc/tTvD2mFT/soy-bean.png",
        },
        { title: "egg", img: "https://i.postimg.cc/w38XrKM6/eggs.png" },
      ],
    },
    {
      title: "storage",
      display: true,
      advice:
        "Take out your macarons at room temperature, 15 mins prior prior eating in order to enjoy their flavour fully. Our macarons should be kept in the bottom of your fridge within 4 days.",
    },
    {
      title: "ingredients",
      display: true,
      ingredients:
        "Pistachios, all purpose flour, powdered sugar, vegan butter, vanilla extract, almond extract, rose water, salt, glaze (powdered sugar, vegan butter, almond milk, vanilla extract)",
    },
    {
      title: "box composition",
      display: false,
      hasBoxComposition: false,
      boxItems: [],
    },
  ],
};
