//Hooks
import MetaTags from "../../hooks/use-metaTags";
import { useParams } from "react-router-dom";

//Components
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ElementWrapper from "../layout/main/ElementWrapper";
import Guarantee from "../layout/main/Guarantee";
import LocationMap from "../layout/main/LocationMap";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import SingleProductCategory from "../ui/Product/SingleProductCategory";

//Data
import { productCategoryTags } from "../../data/metaTags/productCategoryTag";

const Products = () => {
  const params = useParams();

  return (
    <>
      <MetaTags tags={productCategoryTags(params.category)} />
      <ElementWrapperFullWidth>
        <LocationPathGuide backgroundColor={"var(--sandWhite)"} />
        <SingleProductCategory />
      </ElementWrapperFullWidth>
      <ElementWrapper class="aboutUs">
        <Guarantee />
      </ElementWrapper>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <LocationMap />
      </ElementWrapperFullWidth>
    </>
  );
};

export default Products;
