//Hooks
import { motion as m, AnimatePresence } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { setDesktopSearch } from "../../../store/index";
import useWindowSize from "../../../hooks/use-windowDimensions";

//Components
import SearchBox from "./SearchBox";
import SearchIconButton from "../SearchIconButton";
import RecentlySearchedDesktop from "./RecentlySearchedDesktop";

//CSS
import classes from "./SearchIcon.module.css";

const SearchIconComponent = () => {
  const size = useWindowSize();
  const dispatch = useDispatch();

  const isDesktopSearchActive = useSelector(
    (state) => state.isSearchInputActive.desktop
  );

  const searchHandler = () => {
    dispatch(setDesktopSearch(true));
  };

  const style = {
    animate: {
      width: isDesktopSearchActive ? "17rem" : "2.5rem",
      // border: isDesktopSearchActive ? "1px solid var(--brown)" : "0px solid var(--brown)",
    },
    transition: { duration: 0.4, delay: !isDesktopSearchActive ? 0.7 : 0 },
  };

  return (
    <m.li {...style} initial={false} className={classes.searchButtonContainer}>
      <div className={classes.searchButtonAndInputWrapper}>
        <SearchBox />
        <SearchIconButton
          className={classes.icon}
          onClick={searchHandler}
          isDesktopSearchActive={isDesktopSearchActive}
        />
      </div>
      <AnimatePresence>
        {isDesktopSearchActive > 0 && size.width > 1100 && (
          <RecentlySearchedDesktop />
        )}
      </AnimatePresence>
    </m.li>
  );
};

export default SearchIconComponent;
