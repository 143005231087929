//Hooks
import { motion as m, AnimatePresence } from "framer-motion";

//CSS
import classes from "./Button.module.css";

const buttonNameStyle = {
  initial: { top: "-100%", left: "50%", transform: "translate(-50%, -50%)" },
  animate: { top: "50%", left: "50%", transform: "translate(-50%, -50%)" },
  exit: { top: "-100%", left: "50%", transform: "translate(-50%, 50%)" },
  transition: { duration: 0.3, delay: 0 },
};

const Button = ({
  value,
  type,
  buttonName,
  callToAction,
  disable,
  triggerAnimation,
  children,
}) => {
  return (
    <button
      className={`${disable ? classes.disabled : classes.button}`}
      value={value}
      type={type}
      disabled={disable}
      onClick={callToAction}
      style={{
        backgroundColor: triggerAnimation ? "var(--sandWhite)" : null,
        border: "1px solid var(--brown)",
      }}
    >
      <div className={classes.borderInside}>
        <AnimatePresence initial={false}>
          {!triggerAnimation && (
            <m.span {...buttonNameStyle} className={classes.buttonName} key="1">
              {buttonName}
            </m.span>
          )}
          {triggerAnimation && children}
        </AnimatePresence>
      </div>
    </button>
  );
};

export default Button;
