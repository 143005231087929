export const tacData = [
  {
    id: 0,
    header: "Acceptance of Terms",
    text: [
      {
        para: "By accessing, browsing, or using Sienna's services, you agree to comply with and be bound by the terms and conditions outlined herein. If you do not agree to these terms, please refrain from using our services.",
      },
    ],
  },
  {
    id: 1,
    header: "Products",
    text: [
      {
        para: "Products: Sienna applies particular care to the presentation of each Product on the Website. Accordingly, products are described and presented with the highest possible degree of accuracy. The description is intended to apprize the Client of the essential characteristics of Products, before an order is placed.",
      },
      {
        para: "Product Quality: Sienna pays particular attention to the quality of Products available for sale on the Website. Products are stored under optimum conditions for their conservation, and are prepared and packaged in accordance with strict quality rules.",
      },
      {
        para: "Specific Conditions relating to Macaroons: To be enjoyed at their best, Sienna macarons should be kept in a cool place, out of the light. Macarons can be stored in a cool place for a period of 5 days following the date of preparation of the box of macarons, which date appears on the label on the back of the said box of macarons (expiry date will be marked on the reverse of boxes. Sienna particularly alerts Clients to the following requirements: Macarons should not be frozen. Upon the receipt of the package, macarons should be placed in the refrigerator.",
      },
    ],
  },
  {
    id: 2,
    header: "Order Placement and Payment",
    text: [
      {
        para: "Orders: All orders are subject to availability and acceptance.",
      },
      {
        para: "Payment: Payment must be made in full at the time of placing an order. We accept cash & master card/visa.",
      },
      {
        para: "Pricing: Prices are as listed and are subject to change without notice. All prices are inclusive of applicable taxes.",
      },
    ],
  },
  {
    id: 3,
    header: "Cancellations and Refunds",
    text: [
      {
        para: "Cancellations: Cancellations must be made 4 hours before the scheduled pickup.",
      },
      {
        para: "Refunds: Refunds will be issued for cancellations made within the specified time frame. No refunds will be provided for cancellations made after this period.",
      },
    ],
  },
  {
    id: 4,
    header: "Pickup",
    text: [
      {
        para: "Pickup: Customers are responsible for collecting their orders at the agreed-upon time. Failure to collect an order does not entitle the customer to a refund.",
      },
    ],
  },
  {
    id: 5,
    header: "Quality and Satisfaction",
    text: [
      {
        para: "Quality: We strive to provide high-quality pastries. If you are dissatisfied with your order, please contact us within 12 hours of receiving it.",
      },
      {
        para: "Substitutions: We reserve the right to substitute any ingredient in our products with a comparable one of equal or greater value without prior notice.",
      },
    ],
  },
  {
    id: 6,
    header: "Privacy Policy",
    text: [
      {
        para: "Information: We collect and store customer information for order processing and communication purposes. We will not share this information with third parties without your consent.",
      },
      {
        para: "Marketing: By providing your contact information, you consent to receiving marketing communications from us. You can opt-out at any time.",
      },
    ],
  },
  {
    id: 7,
    header: "Liability",
    text: [
      {
        para: "Sienna is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our products or services.",
      },
    ],
  },
  {
    id: 8,
    header: "Governing Law",
    text: [
      {
        para: "These terms and conditions are governed by the laws of [your jurisdiction].",
      },
    ],
  },
  {
    id: 9,
    header: "Changes to Terms",
    text: [
      {
        para: "Sienna reserves the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting on our website.",
      },
    ],
  },
];
