//Hooks
import { motion as m, AnimatePresence } from "framer-motion";
import { IconContext } from "react-icons";
import { useSelector } from "react-redux";
import { isNotEmpty } from "../../../util/validation";
import { useTranslation } from "react-i18next";

//Components
import Paths from "../../layout/header/Paths";
import SearchIconButton from "../SearchIconButton";
import Animation from "../../ui/Animation";
import Error from "../../layout/main/Error";

//Img
import glass from "../../../assets/icons/glass.png";

//CSS
import classes from "./RecentlySearched.module.css";

const RecentlySearched = ({ size }) => {
  const { t } = useTranslation();
  const isMobileSearchActive = useSelector(
    (state) => state.isSearchInputActive.mobile
  );
  const suggestions = useSelector((state) => state.suggestions.suggestions);
  const inputValue = useSelector((state) => state.input.value);

  const suggestionsStyles = {
    initial: { height: 0 },
    animate: { height: "auto" },
    exit: { height: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  const animationsStyle = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  const recentlySearchedStyles = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        y: { duration: 0.5, delay: 0.2 },
        opacity: { duration: 0.2, delay: 0.3 },
      },
    },
    exit: {
      opacity: 0,
      transition: {
        y: {
          duration: size.width < 640 ? 1 : 1,
          delay: size.width < 640 ? 0.2 : 0.1,
        },
        opacity: { duration: 0.1, delay: 0 },
      },
    },
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <m.span className={classes.wrapper} {...recentlySearchedStyles}>
        <AnimatePresence mode="wait">
          {suggestions.length > 0 && inputValue.length > 0 && (
            <div key="suggestionsMobile" className={classes.suggestionsWrapper}>
              <m.ul {...suggestionsStyles} className={classes.suggestions}>
                <Paths
                  arr={suggestions}
                  isSearchClicked={isMobileSearchActive}
                  children={<SearchIconButton />}
                  delayAnimation={0}
                />
              </m.ul>
            </div>
          )}
          {!isNotEmpty(inputValue) && suggestions.length < 1 && (
            <m.div key="searchMobile">
              <Animation
                img={glass}
                animationsStyle={animationsStyle}
                styleContainer={classes.searchStyle}
              />
            </m.div>
          )}
          {suggestions.length < 1 && isNotEmpty(inputValue) && (
            <div key="errorMobile" className={classes.errorWrapper}>
              <Error message={t("notifications.product404_3")} animationsStyle={animationsStyle} />
            </div>
          )}
        </AnimatePresence>
      </m.span>
    </IconContext.Provider>
  );
};

export default RecentlySearched;
