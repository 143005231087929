//Hooks
import { useLocation, useNavigate } from "react-router-dom";

//Icons
import { RxChevronLeft } from "react-icons/rx";

const MobileHeaderBackLocation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBackHandler = () => {
    navigate(-1)
  }

  return (
    <div>
      <span onClick={navigateBackHandler}>
        {location.pathname !== "/" && (
          <RxChevronLeft style={{ fontSize: "1.5rem" }} />
        )}
      </span>
    </div>
  );
};

export default MobileHeaderBackLocation;
