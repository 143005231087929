import { useEffect, useState } from "react";

const usePagination = (totalItems, itemsPerPage, reset, top) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleScrollToTop = (behavior) => {
    window.scrollTo({
      top: top,
      left: 0,
      behavior: behavior,
    });
  };

  const nextPage = () => {
    setCurrentPage(currentPage === totalPages ? 1 : currentPage + 1);
    handleScrollToTop("instant");
  };

  const prevPage = () => {
    setCurrentPage(currentPage === 1 ? totalPages : currentPage - 1);
    handleScrollToTop("instant");
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [reset]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return {
    currentPage,
    nextPage,
    prevPage,
    totalPages,
    startIndex,
    endIndex,
    setCurrentPage,
  };
};

export default usePagination;
