//CSS
import classes from "./PaginationButtons.module.css";

//Icons
import { IoIosArrowUp } from "react-icons/io";

const PaginationButtons = ({
  prevPage,
  nextPage,
  currentPage,
  totalPages,
  totalProducts,
  setCurrentPage,
  top,
}) => {
  const isCurrentPageEqualToOne = currentPage === 1;

  const pointerEvents = (bool) => {
    return {
      pointerEvents: bool ? "none" : null,
      color: bool ? null : "var(--brown)",
    };
  };

  const handleActivePage = (i) => {
    return {
      backgroundColor:
        currentPage === i + 1 ? "var(--brown)" : null,
      color: currentPage === i + 1 ? "var(--sandWhite)" : "var(--brown)",
    };
  };

  const handlePaginationVisibility = (productsLength) => {
    return {
      display: productsLength < 9 ? "none" : "flex",
    };
  };

  const handleNumberPagination = (i) => {
    if (i + 1 === currentPage) return;
    setCurrentPage(i + 1);
    window.scrollTo({
      top: top,
      left: 0,
      behavior: "instant",
    });
  };

  const handleNumbers = (i) => {
    return (
      <button
        key={i + 1}
        onClick={() => handleNumberPagination(i)}
        className={currentPage === i + 1 ? classes.ActivePage : ""}
        style={{ ...handleActivePage(i) }}
      >
        {i + 1}
      </button>
    );
  };

  return (
    <div
      className={classes.PaginationButtonsContainer}
      style={{ ...handlePaginationVisibility(totalProducts) }}
    >
      <div className={classes.PageNumbersContainer}>
        <button
          onClick={prevPage}
          disabled={isCurrentPageEqualToOne}
          style={{ ...pointerEvents(isCurrentPageEqualToOne) }}
        >
          <IoIosArrowUp fontSize={"2rem"} />
        </button>
        {[...Array(totalPages)].map((_, i) => {
          if (i === 0) {
            // Show the first number
            return handleNumbers(i);
          } else if (i === 1) {
            // Show the second number as the current one
            return handleNumbers(i);
          } else if (i === currentPage || i === currentPage - 1) {
            // Show the current number and the one previous to it
            return handleNumbers(i);
          } else if (i === 3 || i === totalPages - 3) {
            // Show dots for numbers after the first 3 and before the last 2
            return <span key={`dots-${i}`}>...</span>;
          } else {
            // Hide the numbers between the first 3 and the last 2
            return null;
          }
        })}
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          style={{ ...pointerEvents(currentPage === totalPages) }}
        >
          <IoIosArrowUp fontSize={"2rem"} />
        </button>
      </div>
    </div>
  );
};

export default PaginationButtons;
