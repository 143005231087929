//Hooks
import { useEffect, useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { useSelector } from "react-redux";
import { isNotEmpty } from "../../../util/validation";
import usePagination from "../../../hooks/use-pagination";
import useWindowSize from "../../../hooks/use-windowDimensions";
import { useTranslation } from "react-i18next";

//Components
import ProductItem from "./ProductItem/ProductItem";
import Error from "../../layout/main/Error";
import Animation from "../Animation";
import Sort from "../Sort";
import PaginationButtons from "../PaginationButtons";

//Img
import glass from "../../../assets/icons/glass.png";

//Data
import { products } from "../../../data/products/all-products/all-products";

//CSS
import classes from "./SearchResultsProducts.module.css";

const SearchResultsProducts = () => {
  const {t} = useTranslation();
  const size = useWindowSize();

  const searchResults = useSelector(
    (state) => state.searchResults.searchResults
  );

  const inputValue = useSelector((state) => state.input.value);

  const subMenus = products.flatMap((prod) => prod.subMenu);

  function findProductsByPaths(productArr, targetPaths) {
    return productArr.filter((prod) =>
      targetPaths.includes(prod.searchQueryPath)
    );
  }

  const data = findProductsByPaths(subMenus, searchResults);

  const [results, setResults] = useState(data);
  const [defaultResults, setDefaultResults] = useState(data);

  useEffect(() => {
    setResults(data);
    setDefaultResults(data);
  }, [searchResults]);

  const itemsPerPage = 9; // Number of items per page
  const totalProducts = results.length;
  const scrollToTopOffset = size.width < 640 ? 0 : 0;

  const {
    currentPage,
    nextPage,
    prevPage,
    totalPages,
    startIndex,
    endIndex,
    setCurrentPage,
  } = usePagination(
    totalProducts,
    itemsPerPage,
    searchResults,
    scrollToTopOffset
  );

  const animationsStyle = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  return (
    <>
      <Sort setData={setResults} data={results} defaultData={defaultResults} />
      <AnimatePresence>
        {results.length > 0 && (
          <m.div {...animationsStyle} className={classes.container}>
            <div className={classes.productContainer}>
              <ul className={classes.gridContainer}>
                {results.slice(startIndex, endIndex).map((prod, i) => (
                  <li key={i}>
                    <ProductItem prod={prod} />
                  </li>
                ))}
              </ul>
              <PaginationButtons
                prevPage={prevPage}
                nextPage={nextPage}
                currentPage={currentPage}
                totalPages={totalPages}
                totalProducts={totalProducts}
                setCurrentPage={setCurrentPage}
                top={scrollToTopOffset}
              />
            </div>
          </m.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {data.length < 1 && isNotEmpty(inputValue) && (
          <div className={classes.errorWrapper}>
            <Error message={t("notifications.product404_3")} animationsStyle={animationsStyle} />
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isNotEmpty(inputValue) && data.length < 1 && (
          <Animation animationsStyle={animationsStyle} img={glass} />
        )}
      </AnimatePresence>
    </>
  );
};

export default SearchResultsProducts;
