//Hooks
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IconContext } from "react-icons";
import { motion as m, AnimatePresence } from "framer-motion";

//Icons
import { PiStorefrontThin, PiTruckThin } from "react-icons/pi";

const DeliveryModel = ({ className }) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);

  const deliveryData = [
    {
      icon: <PiTruckThin style={{ fontSize: "1.5rem" }} />,
      title: t("other.home_delivery"),
      available: false,
    },
    {
      icon: <PiStorefrontThin style={{ fontSize: "1.5rem" }} />,
      title: t("other.order_and_collect"),
      available: true,
    },
  ];

  const styles = {
    initial: { width: "15rem", height: 0, opacity: 0 },
    animate: { width: "15rem", height: "auto", opacity: 1 },
    exit: { width: "15rem", height: 0, opacity: 0 },
    transition: { duration: 0.3, delay: 0, bounce: 0 },
  };

  const textStyles = {
    initial: { transform: "translateY(100%)" },
    animate: { transform: "translateY(0%)" },
    exit: { transform: "translateY(100%)", transition: { delay: 0 } },
    transition: { duration: 0.3, delay: 0.25 },
  };

  return (
    <div>
      <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
        {deliveryData.map(({ icon, title, available }, i) => {
          return (
            <div
              key={i}
              className={className}
              onMouseEnter={() => setPopup(!available)}
              onMouseLeave={() => setPopup(false)}
              style={{
                width: "fit-content",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <span style={{ opacity: available ? 1 : 0.3 }}>{icon}</span>
              <span style={{ opacity: available ? 1 : 0.3 }}>{title}</span>
              <AnimatePresence>
                {!available && popup && (
                  <m.div {...styles} className="popup">
                    <m.div {...textStyles}>{t("popups.delivery")}</m.div>
                  </m.div>
                )}
              </AnimatePresence>
            </div>
          );
        })}
      </IconContext.Provider>
    </div>
  );
};

export default DeliveryModel;
