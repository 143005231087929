//Hooks
import { useDispatch, useSelector } from "react-redux";
import { setProductCategory } from "../../../store/index";
import { useTranslation } from "react-i18next";

//Components
import SwiperMenu from "../SwiperMenu";
import ComponentSplitter from "../../layout/main/ComponentSplitter";

//Data
import { products } from "../../../data/products/all-products/all-products";

//CSS
import classes from "./AllProductCategoriesHeader.module.css";

const allProducts = {
  name: "All products",
  path: "all-the-products",
  hasSubMenu: false,
};

const AllProductCategoriesHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeProductCategory = useSelector(
    (state) => state.activeProductCategory.id
  );

  const productsData = [allProducts, ...products];

  const handleProductCategoryVisibility = (id) => {
    dispatch(setProductCategory(id));
  };

  const swiperSlidesStyles = (id) => {
    return {
      backgroundColor:
        id === activeProductCategory ? "var(--brown)" : "transparent",
      color: id === activeProductCategory ? "var(--sandWhite)" : "var(--brown)",
    };
  };

  return (
    <div className={classes.container}>
      <ComponentSplitter
        subtitle={t("pages.all_of_our_products")}
        title={t("component_splitter.title_click")}
        className={classes.splitter}
      />
      <div className={classes.navigate}>
        <SwiperMenu
          data={productsData}
          onClickFunction={handleProductCategoryVisibility}
          slidesStyle={swiperSlidesStyles}
          sliderStyle={classes.slider}
          slidesClassName={classes.slides}
        />
      </div>
    </div>
  );
};

export default AllProductCategoriesHeader;
