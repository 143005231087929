//Hooks
import { motion as m } from "framer-motion";
import { IconContext } from "react-icons";
import useWindowSize from "../../../../hooks/use-windowDimensions";

//CSS
import classes from "./DropdownOptions.module.css";

const DropdownOptions = (props) => {
  const size = useWindowSize();

  const dropdownStyles = {
    padding: props.padding,
    borderRadius: props.borderRadius,
    borderTop: props.borderTop,
    backgroundColor: props.backgroundColor,
    position: props.gestures.position,
    bottom: props.gestures.bottom,
    height: props.height
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <m.nav
        {...props.animation}
        className={`${classes.options_dropdown} ${props.noScroll} ${props.className}`}
        style={dropdownStyles}
      >
        {size.width < 640 && (
          <div
            {...props.handlers}
            className={classes.barContainer}
            style={{ display: props.display }}
          >
            <div className="bar"></div>
          </div>
        )}
        {props.children}
      </m.nav>
    </IconContext.Provider>
  );
};

export default DropdownOptions;
