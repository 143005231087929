//Hooks
import { useState } from "react";
import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";

//Components
import MobileLanguages from "./Languages";
import Links from "./Links";

//Data
import { mobileHeaderMenu } from "../../../../../data/headerIconsData";

//CSS
import classes from "./DropdownMobileOptions.module.css";

const DropdownMobileOptions = () => {
  const { t } = useTranslation();
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  return (
    <m.div className={`${classes.dropdown} requires-no-scroll`}>
      <ol className={classes.dropdownOptions}>
        {mobileHeaderMenu.map(({ id, icon, name, href, target }) => {
          if (id === 0) {
            return (
              <MobileLanguages
                key={id}
                isLanguageOpen={isLanguageOpen}
                setIsLanguageOpen={setIsLanguageOpen}
                icon={icon}
                name={t("links." + name)}
              />
            );
          } else {
            return (
              <Links
                key={id}
                href={href}
                target={target}
                icon={icon}
                name={t("links." + name)}
              />
            );
          }
        })}
      </ol>
    </m.div>
  );
};

export default DropdownMobileOptions;
