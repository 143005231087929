//Hooks
import MetaTags from "../../hooks/use-metaTags";
import { useTranslation } from "react-i18next";

//Components
import ElementWrapper from "../layout/main/ElementWrapper";
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import Guarantee from "../layout/main/Guarantee";
import Dropdown from "../ui/Dropdown";
import BackgroundSweets from "../layout/main/BackgroundSweets";
import LocationPathGuide from "../layout/main/LocationPathGuide";

//Data
import { tacData } from "../../data/tacData";
import { TermsAndConditionsTags } from "../../data/metaTags/termsAndConditionsTags";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <MetaTags tags={TermsAndConditionsTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide />
        <BackgroundSweets pageName={t("pages.terms_and_conditions")} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="tac">
        <div className="containerStyle">
          <Dropdown data={tacData} />
        </div>
        <Guarantee />
      </ElementWrapper>
    </>
  );
};

export default TermsAndConditions;
