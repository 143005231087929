//Hooks
import { motion as m } from "framer-motion";

//CSS
import classes from "./SearchResultsCloseButton.module.css";

//Icons
import { TfiClose } from "react-icons/tfi";

const SearchResultsCloseButton = ({handleCloseSearch}) => {

  const closeButtonIcon = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  return (
    <m.span
      {...closeButtonIcon}
      className={classes.closeIcon}
      onClick={() => handleCloseSearch()}
    >
      <TfiClose
        style={{
          fontSize: "1.2rem",
          color: " var(--placeholderTransparentBrown)",
        }}
      />
    </m.span>
  );
};

export default SearchResultsCloseButton;
