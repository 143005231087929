//Hooks
import { AnimatePresence, motion as m } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { setSearchResults, clearInputValue } from "../../../../store/index";
import { useTranslation } from "react-i18next";

//Components
import TopSearchSuggestionsSwiper from "./TopSearchSuggestionsSwiper";
import SearchIconButton from "../../SearchIconButton";

//Animations
import { liAnimations } from "../../../../util/animationsVariants";

//Data
import { products } from "../../../../data/products/all-products/all-products";

//CSS
import classes from "./SearchResultsSuggestions.module.css";

const SearchResultsSuggestions = ({ setSearch }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.suggestions.suggestions);

  const handlePaths = (name) => {
    return getLastSegment(replaceDashWithSpace(name));
  };

  const replaceDashWithSpace = (textWithDash) => {
    return textWithDash.replace(/-/g, " ");
  };

  const getLastSegment = (str) => {
    const segments = str.split("/");
    return segments[segments.length - 1];
  };

  const handleSuggestions = (value) => {
    dispatch(setSearchResults(value));
    dispatch(clearInputValue());
  };

  const suggestionsStyles = {
    initial: { height: 0 },
    animate: { height: "auto" },
    exit: { height: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  const listStyles = {
    initial: { overflow: "hidden" },
    animate: { overflow: "auto" },
    exit: { overflow: "hidden", transition: { delay: 0 } },
    transition: { duration: 0, delay: 0.5 },
  };

  const groupPathsByCategory = (paths) => {
    const groupedPaths = {};

    paths.forEach((path) => {
      const [, category] = path.split("/");
      if (!groupedPaths[category]) {
        groupedPaths[category] = { name: category, paths: [path] };
      } else {
        groupedPaths[category].paths.push(path);
      }
    });

    return Object.values(groupedPaths);
  };

  const groupedPaths = groupPathsByCategory(suggestions);

  const isSearchSuggestions = suggestions.length > 0;

  return (
    <m.div {...suggestionsStyles} className={classes.suggestionsContainer}>
      <div>
        <div
          className={`${classes.topResults} ${
            isSearchSuggestions ? classes.borderBottom : null
          }`}
        >
          <div className={classes.topSuggestions}>
            <h4>{t("other.top_searched")}</h4>
            <p>{t("other.discover_our_top_searched_categories")}</p>
            <TopSearchSuggestionsSwiper data={products} setSearch={setSearch} />
          </div>
          <div className={classes.categoryContainer}>
            <span>{suggestions.length}</span>
            <h6>{t("other.total_results_found")}</h6>
          </div>
        </div>
        <m.div {...listStyles} className={classes.suggestionsResults}>
          {groupedPaths.map((category) => (
            <div key={category.name} className={classes.categoryWrapper}>
              <div className={classes.categoryContainer}>
                <span>{category.paths.length}</span>
                <h6>{category.name}</h6>
              </div>
              <ul className={classes.ul}>
                {category.paths.map((item, i) => (
                  <li key={i} onClick={() => handleSuggestions(item)}>
                    <AnimatePresence>
                      <m.span {...liAnimations(i, 0, 0.4)}>
                        <SearchIconButton />
                        <span style={{ textTransform: "none" }}>
                          {handlePaths(item)}
                        </span>
                      </m.span>
                    </AnimatePresence>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </m.div>
      </div>
    </m.div>
  );
};

export default SearchResultsSuggestions;
