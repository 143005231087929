//Components
import Header from "../layout/header/navigation/Header";
import PageLayout from "../layout/main/PageLayout";
import Error from "../layout/main/Error";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="pageHightAjs" />
      <ElementWrapperFullWidth>
        <LocationPathGuide backgroundColor={"var(--sandWhite)"} />
      </ElementWrapperFullWidth>
      <PageLayout>
        <Error message={t("notifications.page404")} />
      </PageLayout>
    </>
  );
};

export default ErrorPage;
