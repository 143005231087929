import Flag from 'react-world-flags'

export const languages = () => {
  return [
    {
      name: "English",
      flag: <Flag code="GB" height="22" width="33" fallback={ <span>ENG</span> }/>,
      tag: "en"
    },
    {
      name: "Македонски",
      flag: <Flag code="MK" height="22" width="33" fallback={ <span>MK</span> }/>,
      tag: "mk"
    },
    {
      name: "Italiano",
      flag: <Flag code="IT" height="22"  fallback={ <span>IT</span> }/>,
      tag: "it"
    },
  ];
};
