//Hooks
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../hooks/use-scrollToTop";

//Components
import Header from "../layout/header/navigation/Header";
import Footer from "../layout/footer/Footer";
import PageLayout from "../layout/main/PageLayout";
import BackToTop from "../ui/BackToTop";

const RootLayout = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="pageHightAjs" />
      <PageLayout>
        <Outlet />
      </PageLayout>
      <Footer />
      <BackToTop />
    </>
  );
};

export default RootLayout;
