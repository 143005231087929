//Hooks
import { motion as m, AnimatePresence } from "framer-motion";

//Animations
import { headerPhotoAnimation} from "../../../../util/animationsVariants"

//CSS
import classes from "./HeaderPhotoContainer.module.css";

const HeaderPhotoContainer = (props) => {
  const photo = props.photo;
  const name = props.name;

  const animation = headerPhotoAnimation();

  return (
    <div className={classes.header_photo_container}>
      <div>
        <AnimatePresence initial={false}>
          <m.img
            src={photo}
            key={name}
            {...animation.photo}
          />
          <div className={classes.photo_text}>
            <p>discover</p>
            <m.h3
              key={name}
              {...animation.text}
            >
              our {name}
            </m.h3>
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HeaderPhotoContainer;
