//Icons
import { PiPlusLight, PiMinusLight  } from "react-icons/pi";

const QuantityContainer = ({ classWrapper, setQuantity, quantity }) => {
  
  const increment = () => {
    setQuantity((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    setQuantity((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  return (
    <div className={classWrapper}>
      <div onClick={() => decrement()}>
        <PiMinusLight
          style={{ fontSize: "1.2rem", opacity: quantity < 2 ? 0.2 : 1 }}
        />
      </div>
      <div>
        <span>{quantity}</span>
      </div>
      <div onClick={() => increment()}>
        <PiPlusLight style={{ fontSize: "1.2rem" }} />
      </div>
    </div>
  );
};

export default QuantityContainer;
