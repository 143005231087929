//Hooks
import { AnimatePresence, motion as m } from "framer-motion";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearInputValue, setMobileSearch } from "../../../store/index";
import useEnterKeyPress from "../../../hooks/use-enterKeyPress";
import { useTranslation } from "react-i18next";

//Components
import SearchIconButton from "../SearchIconButton";

//Data
import { products } from "../../../data/products/all-products/all-products";

//CSS
import classes from "./SearchBoxMobile.module.css";

const SearchBoxMobile = ({ handlers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef(null);

  const isMobileSearchActive = useSelector(
    (state) => state.isSearchInputActive.mobile
  );

  const suggestions = useSelector((state) => state.suggestions.suggestions);

  const handleFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();

    searchBoxHandler();

    // Temporarily move the element off-screen
    ref.current.style.transform = "translateY(-10000px)";

    // Use setTimeout to delay the focus by 2 seconds
    setTimeout(() => {
      // Check if ref.current is not null before accessing its properties or methods
      if (ref.current) {
        // Focus on the element
        ref.current.focus();

        // Use requestAnimationFrame for a smoother transition
        requestAnimationFrame(() => {
          ref.current.style.transform = "none";
        });
      }
    }, 500);
  };

  const searchBoxHandler = () => {
    if (isMobileSearchActive) return;
    dispatch(setMobileSearch(true));
  };

  const cancelButtonHandler = () => {
    dispatch(setMobileSearch(false));

    dispatch(clearInputValue());
  };

  const arrayOfSubMenuPaths = products.flatMap((item) =>
    item.subMenu.map((menu) => menu.searchQueryPath)
  );

  const { inputValue, handleKeyDown, handleInputChange } =
    useEnterKeyPress(arrayOfSubMenuPaths);

  const cancelButtonStyle = {
    initial: { marginRight: "-6rem" },
    animate: { marginRight: 0, transition: { delay: 0.3 } },
    exit: { marginRight: "-6rem" },
    transition: { duration: 0.3 },
  };

  const active =
    suggestions.length > 0 && isMobileSearchActive && inputValue.length > 0;

  return (
    <m.div {...handlers} className={classes.searchBoxContainer}>
      <section>
        <input
          type="search"
          placeholder={t("other.search_products")}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          value={inputValue}
          ref={ref}
          id="searchMobile"
          autoComplete="off"
        />
        <SearchIconButton />
        <AnimatePresence>
          {isMobileSearchActive && (
            <m.button {...cancelButtonStyle} onClick={cancelButtonHandler}>
              {t("buttons.cancel")}
            </m.button>
          )}
        </AnimatePresence>
      </section>
    </m.div>
  );
};

export default SearchBoxMobile;
