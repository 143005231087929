//Hooks
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";
import { motion as m, AnimatePresence } from "framer-motion";
import useWindowSize from "../../../hooks/use-windowDimensions";

//Components
import Arrow from "../../ui/Arrow";

//CSS
import classes from "./Footer.module.css";

//Animations
import { subMenuAnimation } from "../../../util/animationsVariants";

//Logo
// import logo from "../../../assets/images/logo/logo3.png";

//Data
import { footerMenu, socialMenuIcons } from "../../../data/headerIconsData";

const Footer = () => {
  const { t } = useTranslation();
  const [name, setName] = useState({});

  const size = useWindowSize();
  const today = new Date();

  const clickedSubMenu = (name) => {
    if (size.width > 1100) {
      return;
    }
    setName((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  return (
    <section className={classes.container}>
      <div className={classes.logo}>
        {/* <img src={logo} alt="sienna" /> */}
        <h1>Sienna</h1>
        <h5>Pastry boutique</h5>
      </div>
      <div className={classes.footerInfo}>
        <div className={classes.footerInfoLeft}>
          {footerMenu.map((item, i) => {
            return (
              <span
                key={i}
                onClick={() => clickedSubMenu(item.name)}
                className={classes.footerInfoLeftData}
              >
                {t(`footer.${item.name}`)}
                {size.width < 1100 && <Arrow name={name} menuName={item.name} />}
                <AnimatePresence mode="wait">
                  {(name[item.name] || size.width > 1100) && (
                    <m.ol {...subMenuAnimation}>
                      {item.subMenu.map((item, i) => {
                        return (
                          <li key={i} className={classes.li}>
                            <Link to={item.path}>
                              {t(`pages.${item.name}`)}
                            </Link>
                          </li>
                        );
                      })}
                    </m.ol>
                  )}
                </AnimatePresence>
              </span>
            );
          })}
        </div>
        <div className={classes.footerInfoRight}>
          <span>
            {t("pages.contact_us")}
            <ol>
              <li>
                <a href="tel:070359589">070359589</a>
              </li>
              <li>
                <a href="mailto:greg.gego@gmail.com?subject=contact">
                  greg.gego@gmail.com
                </a>
              </li>
            </ol>
          </span>
          <span>
            {t("follow.follow_us")}
            <ol>
              {socialMenuIcons.map((item, i) => {
                return (
                  <li key={i}>
                    <a
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.icon}
                    </a>
                  </li>
                );
              })}
            </ol>
          </span>
        </div>
      </div>
      <div className={classes.copyRight}>
        <span>&#9400; {t("other.copyright")} {today.getFullYear()}</span>
        {size.width > 1100 ? "•" : " "}
        <span>
        {t("other.website_by")}&nbsp;
          <a
            href="https://portfolio-gregoribavaro.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("other.website_developed_by")}
          </a>
        </span>
      </div>
    </section>
  );
};

export default Footer;
