//Hooks
import { useState, useEffect, useMemo, useCallback } from "react";
import { motion as m, AnimatePresence } from "framer-motion";
import useWindowSize from "../../../../hooks/use-windowDimensions";
import useScrollPosition from "../../../../hooks/use-scrollPosition";
import { useSelector, useDispatch } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { useLocation } from "react-router-dom";
import {
  setMenuVisibility,
  setCartVisibility,
  setLanguageVisibility,
  setOptionsVisibility,
  setDesktopSearch,
  setMobileSearch,
} from "../../../../store/index";
import useScrollDirection from "../../../../hooks/use-scrollDirection";

//Animations
import {
  headerHeightAnime,
  dropdownAnimations,
  notificationAnimation,
} from "../../../../util/animationsVariants";

//Components
import DropdownMenu from "../dropdowns/DropdownMenu";
import HamburgerButton from "../hamburgerButton/HamburgerButton";
import SearchIconComponent from "../../../ui/Search/SearchIcon";
import NavigationMenu from "./NavigationMenu";
import DropdownOptions from "../dropdowns/DropdownOptions";
import DropdownCart from "../../../ui/Cart/DropdownCart";
import DropdownLanguages from "../dropdowns/DropdownLanguages";
import MobileHeader from "./MobileHeader";
import Overlay from "../../main/Overlay";
import Notification from "../../../ui/Notification";
import DropdownMobileOptions from "../dropdowns/dropdownMobileOptions/DropdownMobileOptions";

//CSS
import classes from "./Header.module.css";

//Logo
// import logo from "../../../../assets/images/logo/logo3.png";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const direction = useScrollDirection();
  const isNotification = useSelector((state) => state.isNotification.isOpen);
  const areMenusOpened = useSelector((state) => state.areMenusOpened);
  const isOverlayClicked = useSelector(
    (state) => state.isOverlayClicked.isOverlayClicked
  );
  const isDesktopSearchActive = useSelector(
    (state) => state.isSearchInputActive.desktop
  );

  const isMobileSearchActive = useSelector(
    (state) => state.isSearchInputActive.mobile
  );

  const [gestureData, setGestureData] = useState({});
  const size = useWindowSize();
  const scrollPosition = useScrollPosition();

  const isOneMenuOpen = Object.values(areMenusOpened).some((menu) => menu);

  useEffect(() => {
    dispatch(setMobileSearch(false));
  }, [dispatch, !areMenusOpened.menu]);

  /*--------------------------------- Close menus if options is open ---------------------------------*/

  const closeMenuActions = {
    menu: useCallback(() => dispatch(setMenuVisibility(false)), [dispatch]),
    language: useCallback(
      () => dispatch(setLanguageVisibility(false)),
      [dispatch]
    ),
    cart: useCallback(() => dispatch(setCartVisibility(false)), [dispatch]),
  };

  useEffect(() => {
    if (areMenusOpened.options) {
      Object.entries(areMenusOpened)
        .filter(([menuType, isOpen]) => isOpen && closeMenuActions[menuType])
        .forEach(([menuType]) => closeMenuActions[menuType]());
    }
  }, [areMenusOpened.options, closeMenuActions]);

  /*--------------------------------- Close menus on and overlay click or path change ---------------------------------*/

  useEffect(() => {
    const menuActions = {
      menu: setMenuVisibility,
      language: setLanguageVisibility,
      cart: setCartVisibility,
      options: setOptionsVisibility,
    };

    for (const [menuType, action] of Object.entries(menuActions)) {
      if (areMenusOpened[menuType]) {
        dispatch(action(false));
        break;
      }
    }

    dispatch(setDesktopSearch(false));
  }, [dispatch, isOverlayClicked, location.pathname]);

  /*--------------------------------- Close options menu if other menus are opened ---------------------------------*/

  const shouldCloseOptions = useMemo(() => {
    return (
      areMenusOpened.menu || areMenusOpened.language || areMenusOpened.cart
    );
  }, [areMenusOpened.menu, areMenusOpened.language, areMenusOpened.cart]);

  useEffect(() => {
    if (shouldCloseOptions) {
      dispatch(setOptionsVisibility, false);
    }
  }, [shouldCloseOptions, dispatch]);

  /*--------------------------------- Swipe Events ---------------------------------*/

  const handleMenuClose = (menuType, actionType) => {
    if (areMenusOpened[menuType]) {
      dispatch(actionType(false));
    }
  };

  const handlers = useSwipeable({
    onSwiped: ({ dir }) => {
      setGestureData((prev) => ({ ...prev, deltaY: 0 }));

      if (dir === "Down") {
        handleMenuClose("menu", setMenuVisibility);
        handleMenuClose("cart", setCartVisibility);
        handleMenuClose("language", setLanguageVisibility);
        handleMenuClose("options", setOptionsVisibility);
      } else if (dir === "Up" && areMenusOpened.menu) {
        dispatch(setMobileSearch(true));
      }
    },
    onSwiping: (eventData) => {
      setGestureData(eventData);
    },
  });

  /*--------------------------------- Mobile gestures ---------------------------------*/

  const gestures = () => {
    let bottom = 0;

    if (isMobileSearchActive) {
      return { bottom: 0 };
    }

    if (gestureData.deltaY && -gestureData.deltaY < 0) {
      bottom = -gestureData.deltaY;
    }

    return {
      position: size.width < 640 ? "relative" : "static",
      bottom,
    };
  };

  /*--------------------------------- Menu handler ---------------------------------*/

  const closeDropdownHandler = () => {
    if (size.width < 1100) return;
    dispatch(setMenuVisibility(false));
  };

  /*--------------------------------- Set mobile navigation on scroll ---------------------------------*/

  const mobileHeaderSetterBottom = () => {
    if (size.width <= 640) {
      if (
        direction === "down" &&
        scrollPosition > 100 &&
        !areMenusOpened.menu
      ) {
        return "-5rem";
      } else if (direction === "up") {
        return 0;
      }
    }
  };

  /*--------------------------------- Header left section ---------------------------------*/

  const headerLeftProps = {
    ...headerHeightAnime({
      scrollPosition: scrollPosition,
    }),
  };

  const headerLeftSection = (
    <m.div className={classes.header_left} {...headerLeftProps}>
      {size.width > 640 && <HamburgerButton />}
    </m.div>
  );

  /*--------------------------------- Header right section ---------------------------------*/

  const headerRightProps = {
    ...headerHeightAnime({
      scrollPosition: scrollPosition,
    }),
  };

  const headerRightSection = (
    <m.nav className={classes.header_right} {...headerRightProps}>
      <ul className={classes.header_right_ul}>
        <NavigationMenu />
        {size.width > 1100 && <SearchIconComponent />}
      </ul>
    </m.nav>
  );

  const boxShadow =
    areMenusOpened.menu ||
    areMenusOpened.language ||
    isNotification ||
    size.width < 640
      ? null
      : "rgba(0, 0, 0, 0.09) 0px 2px 24px 0px";

  const height =
    scrollPosition > 90 || (size.width > 640 && size.width < 1100)
      ? "calc(100vh - 4rem)"
      : "calc(100vh - 6rem)";

  return (
    <AnimatePresence initial={false}>
      {size.width < 640 && <MobileHeader key="mobileHeader" />}
      <AnimatePresence key="overlay">
        {(isOneMenuOpen || isDesktopSearchActive) && <Overlay />}
      </AnimatePresence>
      <m.header
        className={classes.header}
        style={{
          bottom: mobileHeaderSetterBottom(),
          boxShadow: boxShadow,
        }}
      >
        <span style={{ display: "flex", position: "relative", height: "100%" }}>
          {headerLeftSection}
          <m.div className={classes.header_logo} {...headerLeftProps}>
            {/* <img src={logo} alt="sienna" /> */}
            <h1>Sienna</h1>
          </m.div>
          {headerRightSection}
        </span>
        {/*--------------------------------- Menu ---------------------------------*/}
        <AnimatePresence mode="wait">
          {areMenusOpened.menu && (
            <DropdownMenu
              key="menu"
              animations={dropdownAnimations({ size, height: "auto" })}
              onLeave={closeDropdownHandler}
              handlers={{ ...handlers }}
              gestures={gestures}
              borderRadius={size.width < 640 ? "0.2rem" : 0}
            />
          )}
          {/*--------------------------------- Cart ---------------------------------*/}
          {areMenusOpened.cart && (
            <DropdownOptions
              key="cart"
              handlers={{ ...handlers }}
              display="flex"
              noScroll="requires-no-scroll-cart"
              animation={dropdownAnimations({ size, height: height })}
              gestures={gestures()}
              height={"100vh"}
            >
              <DropdownCart />
            </DropdownOptions>
          )}
          {/*--------------------------------- Languages ---------------------------------*/}
          {areMenusOpened.language && (
            <DropdownOptions
              key="lang"
              display="flex"
              // noScroll="requires-no-scroll"
              animation={dropdownAnimations({ size })}
              gestures={{}}
            >
              <DropdownLanguages />
            </DropdownOptions>
          )}
          {/*--------------------------------- Options ---------------------------------*/}
          {areMenusOpened.options && (
            <DropdownOptions
              key="options"
              handlers={{ ...handlers }}
              display="flex"
              noScroll="requires-no-scroll"
              animation={dropdownAnimations({ size })}
              gestures={gestures()}
              styles={{
                justifyContent: "center",
                borderBottom: "none",
                padding: "1rem 0 0",
              }}
            >
              <DropdownMobileOptions />
            </DropdownOptions>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isNotification && (
            <Notification animation={notificationAnimation(size.width)} />
          )}
        </AnimatePresence>
      </m.header>
    </AnimatePresence>
  );
};

export default Header;
