//Hooks
import MetaTags from "../../hooks/use-metaTags";

//Components
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import ElementWrapper from "../layout/main/ElementWrapper";
import Guarantee from "../layout/main/Guarantee";
import LocationMap from "../layout/main/LocationMap";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import SearchResultsProducts from "../ui/Product/SearchResultsProducts";
import SearchResultsHeader from "../ui/Search/SearchResultsHeader/SearchResultsHeader";

//Data
import { searchResultsTags } from "../../data/metaTags/searchResultsTags";

const SearchResults = () => {
  return (
    <>
      <MetaTags tags={searchResultsTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide backgroundColor={"var(--sandWhite)"} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="aboutUs">
        <SearchResultsHeader />
        <SearchResultsProducts />
        <Guarantee />
      </ElementWrapper>
      <ElementWrapperFullWidth backgroundColor={"var(--lightSecondary)"}>
        <LocationMap />
      </ElementWrapperFullWidth>
    </>
  );
};

export default SearchResults;
