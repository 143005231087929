//Icons
import {
  PiHandbagSimpleFill,
  PiStorefrontThin,
  PiStorefrontFill,
  PiHouseLineThin,
  PiHouseLineFill,
  PiUserThin,
  PiUserFill,
  PiWarningCircleThin,
  PiWarningCircleFill,
  PiGlobeHemisphereWestThin,
  PiGlobeHemisphereWestFill,
  PiInstagramLogoThin,
  PiFacebookLogoThin,
  PiHandbagThin,
  PiPhoneCallThin,
  PiEnvelopeThin,
  PiFilePdfThin,
  PiFlagThin,
} from "react-icons/pi";

export const headerIcons = [
  {
    icon: <PiHouseLineThin />,
    mobileFillIcon: <PiHouseLineFill />,
    nameIcon: "home",
    path: "/",
    type: "menu",
  },
  {
    icon: <PiUserThin />,
    mobileFillIcon: <PiUserFill />,
    nameIcon: "contact",
    path: "/contact",
    type: "menu",
  },
  {
    icon: <PiWarningCircleThin style={{ rotate: "180deg" }} />,
    mobileFillIcon: <PiWarningCircleFill style={{ rotate: "180deg" }} />,
    nameIcon: "about",
    path: "/about",
    type: "menu",
  },
  {
    icon: <PiGlobeHemisphereWestThin />,
    mobileFillIcon: <PiGlobeHemisphereWestFill />,
    nameIcon: "language",
    type: "options",
  },
  {
    icon: <PiHandbagThin />,
    mobileFillIcon: <PiHandbagSimpleFill />,
    nameIcon: "cart",
    type: "options",
    style: "cart",
  },
  {
    icon: <PiStorefrontThin />,
    mobileFillIcon: <PiStorefrontFill />,
    nameIcon: "products",
    type: "options",
  },
];

export const socialMenuIcons = [
  {
    icon: <PiInstagramLogoThin style={{ fontSize: "1.8rem" }} />,
    nameIcon: "instagram",
    href: "https://www.instagram.com/djordjevic98/",
  },
  {
    icon: <PiFacebookLogoThin style={{ fontSize: "1.8rem" }} />,
    nameIcon: "facebook",
    href: "https://www.facebook.com/gala.gjorgjevik",
  },
];

export const mobileHeaderMenu = [
  {
    id: 0,
    icon: <PiGlobeHemisphereWestThin style={{ fontSize: "1.7rem" }} />,
    name: "language",
  },
  {
    id: 1,
    icon: <PiPhoneCallThin style={{ fontSize: "1.7rem" }} />,
    name: "order",
    href: "tel:070359589",
    target: "_self"
  },
  {
    id: 2,
    icon: <PiEnvelopeThin style={{ fontSize: "1.7rem" }} />,
    name: "email_us",
    href: "mailto:greg.gego@gmail.com?subject=contact",
    target: "_self"
  },
  {
    id: 3,
    icon: <PiFilePdfThin style={{ fontSize: "1.7rem" }} />,
    name: "download_PDF_menu",
    href: "https://docs.google.com/document/d/1JLhYy78_uMKSdimQB-rHRkJL6vzqw_SNy4IwjX9_Nps/edit?usp=sharing",
    target: "_blank",
  },
  {
    id: 4,
    icon: <PiFlagThin style={{ fontSize: "1.7rem" }} />,
    name: "report_a_problem",
    href: "mailto:greg.gego@gmail.com?subject=report a problem",
    target: "_self"
  },
];

// FOOTER MENU

export const footerMenu = [
  {
    name: "sienna",
    subMenu: [
      { name: "pastries", path: "all-the-products/pastries" },
      { name: "cookies", path: "all-the-products/cookies" },
      { name: "gift ideas", path: "all-the-products/gift-ideas" },
    ],
  },
  {
    name: "help",
    subMenu: [
      { name: "contact", path: "contact" },
      { name: "faq", path: "faq" },
    ],
  },
  {
    name: "more information",
    subMenu: [
      { name: "terms_and_conditions", path: "terms-and-conditions" },
      { name: "legal_notice", path: "legal-notice" },
    ],
  },
];
