//Hooks
import { motion as m } from "framer-motion";
import { useSelector } from "react-redux";

//CSS
import classes from "./CustomHamburgerButton.module.css";

const CustomHamburgerButton = () => {
  const isMenuOpened = useSelector((state) => state.areMenusOpened);

  const hamburgerWrapperClass = !isMenuOpened.menu
    ? classes.wrapperMenu
    : `${classes.wrapperMenu} ${classes.open}`;

  return (
    <div className={hamburgerWrapperClass}>
      <m.div
        className={`${classes.lineMenu} ${classes.half} ${classes.start}`}
      ></m.div>
      <m.div className={classes.lineMenu}></m.div>
      <m.div
        className={`${classes.lineMenu} ${classes.half} ${classes.end}`}
      ></m.div>
    </div>
  );
};

export default CustomHamburgerButton;
