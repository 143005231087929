//Hooks
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { useState, useEffect, useMemo } from "react";
import useAddItemToCart from "../../../hooks/use-addItemToCart";

//IconAnimation
import checkAnimation from "../../../assets/anim/Animation - check.json";

//CSS
import classes from "./ProductDetailsComponent.module.css";

//Components
import Button from "../Button";
import QuantityContainer from "./QuantityContainer";
import DeliveryModel from "./DeliveryModel";
import BoxSizes from "./BoxSizes";
import IconAnimation from "../iconAnimations/IconAnimation";

const deliveryModelMessage =
  "In an effort to enhance our service and ensure timely processing, we kindly request that all orders be placed at least one day prior to your desired pickup date.";

const OrderContainer = ({ data }) => {
  const { t } = useTranslation();
  const orderData = data.productInfo[0];
  const productData = useMemo(() => data, [data]);

  const [itemToCart, setItemToCart] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [boxSize, setBoxSize] = useState({
    pieces: orderData.sizes[0].pieces,
    price: orderData.sizes[0].price,
  });

  useEffect(() => {
    setBoxSize({
      pieces: orderData.sizes[0].pieces,
      price: orderData.sizes[0].price,
    });
  }, [orderData.sizes]);

  const updatedProductData = {
    ...productData,
    quantity: quantity,
    price: boxSize.price,
    pieces: boxSize.pieces,
  };

  const handleItemToCart = useAddItemToCart({
    updatedProductData,
    productData,
    boxSize,
    quantity,
    setQuantity,
    setBoxSize,
    orderData,
  });

  const addItemToCart = () => {
    if (itemToCart) return;
    handleItemToCart();
    setItemToCart((prev) => !prev);
    setTimeout(() => {
      setItemToCart((prev) => !prev);
    }, [2000]);
  };

  const liStyle = {
    width: orderData.sizes.length < 2 ? "fit-content" : "100%",
  };

  return (
    <span>
      <div className={classes.productDescription}>
        <p>{orderData.description}</p>
      </div>
      <div className={classes.deliveryWrapper}>
        <h6>{t("other.delivery_model")}</h6>
        <DeliveryModel className={classes.delivery} />
        <p>{deliveryModelMessage}</p>
      </div>
      <BoxSizes
        setBoxSize={setBoxSize}
        boxSize={boxSize}
        orderData={orderData}
        classContainer={classes.boxSizes}
        classWrapper={classes.sizes}
        liStyle={liStyle}
      />
      <div className={classes.quantityContainer}>
        <div className={classes.priceAndQuantity}>
          <div className={classes.priceWrapper}>
            <span>{boxSize.price + " " + t("other.currency")}</span>
          </div>
          <QuantityContainer
            classWrapper={classes.quantityWrapper}
            setQuantity={setQuantity}
            quantity={quantity}
          />
        </div>
        <Button
          buttonName={t("buttons.add_to_cart")}
          callToAction={addItemToCart}
          triggerAnimation={itemToCart}
        >
          <IconAnimation name={"Added"}>
            <Lottie animationData={checkAnimation} />
          </IconAnimation>
        </Button>
      </div>
    </span>
  );
};

export default OrderContainer;
