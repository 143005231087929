//Hooks
import { useNavigate } from "react-router-dom";
import { motion as m } from "framer-motion";

//Animations
import { subMenuAnimation } from "../../../../util/animationsVariants";

//CSS
import classes from "./DropdownSubMenu.module.css";

const DropdownSubMenu = ({data, menuName}) => {
  const navigate = useNavigate();

  // const parentPath = menuName.replace(/ /g, '-').toLowerCase();

  const handleSubMenuClick = (path) => {
    navigate(`all-the-products/${path}`);
  };

  return (
    <m.ol className={classes.subMenu} {...subMenuAnimation}>
      {data.map((subMenu, i) => {
        return (
          <li
            key={i}
            className={classes.subMenu_items}
            onClick={() => handleSubMenuClick(subMenu.path)}
          >
            {subMenu.name}
          </li>
        );
      })}
    </m.ol>
  );
};

export default DropdownSubMenu;
