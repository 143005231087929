//Hooks
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Container
import ImageContainer from "./ImageContainer";

//CSS
import classes from "./LinkToProductDetail.module.css";

const LinkToProductDetail = ({ isVisible, productData }) => {
  const { t } = useTranslation();
  const productPrice = productData.productInfo[0].sizes[0].price;
  const productSizes = productData.productInfo[0].sizes.length;

  return (
    <Link to={`/all-the-products/${productData.path}`}>
      <ImageContainer
        src={productData.imgs[0]}
        alt={productData.name}
        hasTag={productData.tag.hasTag}
        tagColor={productData.tag.color}
        tagTitle={productData.tag.tagTitle}
        isVisible={isVisible}
        initialScale={1.1}
        animateScale={1}
      />
      <div className={classes.productInfo}>
        <div className={classes.nameAndPrice}>
          <h4>{productData.name}</h4>
          <p>
            {productSizes > 1 && t("other.from")} {productPrice}{" "}
            {t("other.currency")}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default LinkToProductDetail;
