import { useState, useEffect } from 'react';

const useImageLoader = (src) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
    return () => {
      // Cleanup function to remove event listener
      img.onload = null;
    };
  }, [src]);

  return imageLoaded;
};

export default useImageLoader;
