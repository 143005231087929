//CSS
import classes from "./ComponentSplitter.module.css";

const ComponentSplitter = ({className, title, subtitle, titleStyles, backgroundColor}) => {
  return (
    <section
      className={`${classes.splitterContainer} ${className}`}
      style={{ backgroundColor: backgroundColor}}
    >
      <h5 style={{...titleStyles}}>{title}</h5>
      <h1>{subtitle}</h1>
    </section>
  );
};

export default ComponentSplitter;
