//CSS
import classes from "./SocialMediaIconsMobile.module.css";

//Data
import { socialMenuIcons } from "../../data/headerIconsData";

const SocialMediaIconsMobile = () => {
  return (
    <div className={classes.iconsTablet}>
      <ol>
        {socialMenuIcons.map(({ icon, nameIcon, href }, i) => {
          return (
            <li key={i}>
              <a target="_blank" rel="noreferrer" href={href}>
                {icon}
                {nameIcon}
              </a>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default SocialMediaIconsMobile;
