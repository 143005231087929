import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  debug: false,
  returnObjects: true,
  supportedLngs: ["en", "mk"],
  fallbackLng: "en",
  // detection: {
  //   order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
  //   caches: ["cookie"],
  // },
  // backend: {
  //   loadPath: "/public/locales/{{lng}}/translation.json",
  // },
});

export default i18n;
