//Components
import Animation from "../Animation";

//Img
import cart from "../../../assets/icons/shopping-bag.png";

//CSS
import classes from "./EmptyCart.module.css";

const EmptyCart = () => {
  const animationsStyle = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, delay: 0 },
  };

  return (
    <div className={classes.emptyContainer}>
      <div className={classes.emptyCartAnimationContainer}>
        <Animation
          animationsStyle={animationsStyle}
          styleAnimation={classes.animation}
          styleContainer={classes.animationContainer}
          img={cart}
        />
        <h1>Your shopping bag is empty.</h1>
      </div>
    </div>
  );
};

export default EmptyCart;
