//Hooks
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//Components
import CartItem from "./CartItem";
import CartItemHeader from "./CartItemHeader";

//CSS
import classes from "./ShoppingCart.module.css";

const ShoppingCart = ({ handleCalculatePrice, handleRemoveItem }) => {
  const { t } = useTranslation();

  const cartItems = useSelector((state) => state.cart.cart);

  return (
    <div className={classes.shoppingCart}>
      <div className={classes.shoppingCartHeader}>
        <h2>{t("other.shopping cart")}</h2>
        <h2> {t("count.keyWithCount", { count: cartItems.length })}</h2>
      </div>
      <div className={classes.shoppingCartWrapper}>
        <ol className={classes.shoppingCartItemsHeader}>
          {cartItems.length > 0 && <CartItemHeader />}
        </ol>
        <ol className={classes.shoppingCartItems}>
          {cartItems.map((item, i) => (
            <CartItem
              key={i}
              item={item}
              handleCalculatePrice={handleCalculatePrice}
              handleRemoveItem={handleRemoveItem}
            />
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ShoppingCart;
