export const heroData = [
  {
    name: "Christmas cookies",
    photo: "https://i.postimg.cc/VvQHLf2D/christmas.jpg",
    description: "Available now",
  },
  {
    name: "Sugar cookies",
    photo: "https://i.postimg.cc/BvCvCbB1/christmas2.jpg",
    description: "Novelty",
  },
  {
    name: "cinnamon cookies",
    photo: "https://i.postimg.cc/fT0TrBvs/cinemon.jpg",
    description: "Discover",
  },
];

