//Hooks
import MetaTags from "../../hooks/use-metaTags";
import { useTranslation } from "react-i18next";

//Components
import ElementWrapper from "../layout/main/ElementWrapper";
import ElementWrapperFullWidth from "../layout/main/ElementWrapperFullWidth";
import Guarantee from "../layout/main/Guarantee";
import Dropdown from "../ui/Dropdown";
import LocationPathGuide from "../layout/main/LocationPathGuide";
import BackgroundSweets from "../layout/main/BackgroundSweets";

//Data
import { legalNoticeData } from "../../data/legalNoticeData";
import { legalNoticeTags } from "../../data/metaTags/legalNoticeTags";

const LegalNotice = () => {
  const {t} = useTranslation()
  return (
    <>
      <MetaTags tags={legalNoticeTags} />
      <ElementWrapperFullWidth>
        <LocationPathGuide />
        <BackgroundSweets pageName={t("pages.legal_notice")} />
      </ElementWrapperFullWidth>
      <ElementWrapper class="legalNotice">
        <div className="containerStyle">
          <Dropdown data={legalNoticeData} />
        </div>
        <Guarantee />
      </ElementWrapper>
    </>
  );
};

export default LegalNotice;
