export function headerHeightAnime({ scrollPosition }) {
  return {
    animate: {
      height: scrollPosition > 90 ? "4rem" : "6rem",
    },
    transition: {
      duration: 0.4,
      delay: 0,
    },
  };
}

export function dropdownAnimations({ size, height }) {
  if (size.width > 640) {
    return {
      initial: {
        y: "-100%",
        display: "none",
      },
      animate: {
        y: 0,
        height: height,
        display: "flex",
        transition: {
          // delay: 0.4,
          duration: 0.4,
          height: { duration: 0.4, delay: 0 },
        },
      },
      exit: {
        y: "-100%",
        display: "none",
        transition: {
          duration: 0.2,
          ease: "easeIn",
          delay: 0,
          height: { duration: 0.4, delay: 0 },
          display: { delay: 0.2, duration: 0 },
        },
      },
      transition: {
        duration: 0.5,
        ease: "easeOut",
        delay: 0.2,
        bounce: 0,
        height: { duration: 0.4, delay: 0 },
      },
    };
  } else {
    return {
      initial: {
        y: "150%",
        display: "none",
      },
      animate: {
        y: 0,
        display: "flex",
        transition: {
          duration: 0.3,
        },
      },
      exit: {
        y: "150%",
        display: "none",
        transition: {
          duration: 0.3,
          ease: "easeIn",
          delay: 0,
          display: { delay: 0.3, duration: 0 },
        },
      },
      transition: {
        duration: 0.5,
        ease: "easeOut",
        delay: 0,
        bounce: 0,
      },
    };
  }
}

export const subMenuAnimation = {
  initial: {
    height: 0,
  },
  animate: {
    height: "auto",
    opacity: 1,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
        delay: 0.15,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
        delay: 0.2
      },
    },
  },
};

export function headerPhotoAnimation() {
  const photo = {
    initial: { x: "100%" },
    animate: { x: 0, transition: { duration: 0.5, delay: 0.1 } },
    exit: { x: "-100%", transition: { duration: 0.3 } },
  };

  const text = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.7, delay: 0.5 },
  };

  return {
    photo,
    text,
  };
}

export function mobileHeaderLogoAndPathAnimation({ location }) {
  const logo = {
    initial: false,
    animate: {
      top: location.pathname !== "/" ? "-200%" : "50%",
    },
    transition: {
      duration: 0.5,
    },
  };

  const path = {
    initial: false,
    animate: {
      top: location.pathname === "/" ? "-200%" : "50%",
    },
    transition: { duration: 0.2, delay: 0.2 },
  };

  return {
    logo,
    path,
  };
}

export function hideLisOnSearch({ isDesktopSearchActive, size }) {
  return {
    initial: false,
    animate: {
      x: isDesktopSearchActive && size.width > 1100 ? "450%" : 0,
      position: "relative",
    },
  };
}

export const clickedTopicAnimation = {
  initial: {
    x: "-100%",
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        duration: 0.6,
        delay: 0.4,
      },
      opacity: {
        duration: 0.5,
        delay: 0.2,
      },
    },
  },
  exit: {
    x: "100%",
    opacity: 0,
    transition: {
      x: {
        duration: 0.3,
        delay: 0.1,
      },
      opacity: {
        duration: 0.3,
        delay: 0.1,
      },
    },
  },
};

export const notificationAnimation = (size) => {
  return {
    initial: {
      top: "-10rem",
      display: "none",
      right: "0.5rem",
      left: size < 1100 ? "50%" : null,
      transform: size < 1100 ? "translateX(-50%)" : null,
    },
    animate: {
      top: "0.5rem",
      display: "flex",
      right: "0.5rem",
      left: size < 1100 ? "50%" : null,
      transform: size < 1100 ? "translateX(-50%)" : null,
      transition: {
        duration: 0.4,
      },
    },
    exit: {
      top: "-10rem",
      display: "none",
      right: "0.5rem",
      left: size < 1100 ? "50%" : null,
      transform: size < 1100 ? "translateX(-50%)" : null,
      transition: {
        duration: 0.2,
        ease: "easeIn",
        delay: 0,
        display: { delay: 0.2, duration: 0 },
      },
    },
    transition: {
      duration: 0.5,
      ease: "easeOut",
      bounce: 0,
    },
  };
};

export function PresentationAnimation({ isInViewContainer }) {
  const photo = {
    animate: {
      top: isInViewContainer ? "100%" : "0%",
      height: "100%",
      width: "100%",
    },
    transition: { duration: 1, delay: 0.2, ease: "easeOut" },
  };

  const text = {
    animate: {
      y: isInViewContainer ? 0 : "200%",
      opacity: isInViewContainer ? 1 : 0,
    },
    transition: {
      duration: 1,
      delay: 0.3,
      ease: "easeOut",
      opacity: { delay: 0.2, duration: 1 },
    },
  };

  return {
    photo,
    text,
  };
}

export const liAnimations = (i, delay, duration) => {
  return {
    initial: { y: 100, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: 0, opacity: 1, transition: { delay: 0 } },
    transition: {
      delay: delay + i * 0.2,
      bounce: false,
      duration: duration,
    },
  };
};

export const titlesStyles = (isSearchClicked, delay) => {
  return {
    animate: {
      scale: isSearchClicked ? 1 : 0.3,
      filter: isSearchClicked ? "blur(0px)" : "blur(10px)",
    },
    transition: {
      delay: delay,
      duration: 0.4,
    },
  };
};
