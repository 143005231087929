const SideLinks = () => {
  const productsLinkSchema = {
    "@context": "http://schema.org",
    "@type": "SiteNavigationElement",
    name: "All Products",
    url: "https://www.sienna.mk/all-the-products",
  };

  const cupcakesLinkSchema = {
    "@context": "http://schema.org",
    "@type": "SiteNavigationElement",
    name: "Cupcakes",
    url: "https://www.sienna.mk/all-the-products/cupcakes",
  };

  const faqLinkSchema = {
    "@context": "http://schema.org",
    "@type": "SiteNavigationElement",
    name: "FAQ",
    url: "https://www.sienna.mk/faq",
  };

  const contactLinkSchema = {
    "@context": "http://schema.org",
    "@type": "SiteNavigationElement",
    name: "Contact",
    url: "https://www.sienna.mk/contact",
  };

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(productsLinkSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(cupcakesLinkSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(faqLinkSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(contactLinkSchema)}
      </script>
    </>
  );
};

export default SideLinks;
