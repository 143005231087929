//CSS
import classes from "./Links.module.css";

const Links = ({ icon, name, href, target }) => {
  return (
    <li className={classes.lisOptions}>
      <a href={href} target={target}>
        <span>{icon}</span>
        <span>{name}</span>
      </a>
    </li>
  );
};

export default Links;
