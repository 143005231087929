//Hooks
import { IconContext } from "react-icons";
import { useTranslation } from "react-i18next";

//Components
import Button from "../../ui/Button";

//Data
import { socialMenuIcons } from "../../../data/headerIconsData";

//CSS
import classes from "./FollowUsOn.module.css";

const FollowUsOn = () => {
  const { t } = useTranslation();
  const { follow_us, find_us } = t("follow");
  const { discover } = t("buttons")

  return (
    <div className={classes.container}>
      <div>
        <div>
          <h1>{follow_us}</h1>
          <h5>@SIENNAPASTRYBOUTIQUE</h5>
        </div>
        <Button buttonName={discover} />
      </div>
      <div>
        <span>{find_us}:</span>
        <ol>
          <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
            {socialMenuIcons.map(({ icon, href }, i) => {
              return (
                <li key={i}>
                  <a target="_blank" rel="noreferrer" href={href}>
                    {icon}
                  </a>
                </li>
              );
            })}
          </IconContext.Provider>
        </ol>
      </div>
    </div>
  );
};

export default FollowUsOn;
