//Hooks
import { useTranslation } from "react-i18next";

//Data
import { guaranteeData } from "../../../data/guaranteeData";

//CSS
import classes from "./Guarantee.module.css";

const Guarantee = () => {
  const { t } = useTranslation();
  return (
    <section className={classes.container}>
      <h1>{t("guarantee.why_choose")}</h1>
      <div className={classes.gridContainer}>
        {guaranteeData.map((item, i) => {
          return (
            <div key={i}>
              <img src={item.icon} alt={item.header} />
              <h4>{t(`guarantee.${item.header}`)}</h4>
              <span>{t(`guarantee.${item.text}`)}</span>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Guarantee;
