//Hook
import { memo } from "react";

//CSS
import classes from "./ElementWrapperFullWidth.module.css";

const ElementWrapperFullWidth = memo(function ElementWrapper(props) {
  return (
    <section
      className={`${classes.container} ${props.class}`}
      style={{ backgroundColor: props.backgroundColor, padding: props.padding }}
    >
      {props.children}
    </section>
  );
});

export default ElementWrapperFullWidth;
