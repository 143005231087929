//Hooks
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { motion as m } from "framer-motion";
import useWindowSize from "../../../../hooks/use-windowDimensions";
import { IconContext } from "react-icons";
import {
  setMenuVisibility,
  setCartVisibility,
  setLanguageVisibility,
  setOptionsVisibility,
} from "../../../../store/index";
import { useTranslation } from "react-i18next";

//Data
import { headerIcons } from "../../../../data/headerIconsData";

//Animations
import { hideLisOnSearch } from "../../../../util/animationsVariants";

//CSS
import classes from "./NavigationMenu.module.css";

const NavigationMenu = () => {
  const { t } = useTranslation();

  const areMenusOpened = useSelector((state) => state.areMenusOpened);

  const isDesktopSearchActive = useSelector(
    (state) => state.isSearchInputActive.desktop
  );

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const location = useLocation();
  const size = useWindowSize();

  let startingId;

  if (location.pathname === "/") {
    startingId = 0;
  } else if (location.pathname === "/contact") {
    startingId = 1;
  } else if (location.pathname === "/about") {
    startingId = 2;
  }

  const [activeAndPrev, setActiveAndPrev] = useState({
    prev: startingId,
    active: startingId,
  });

  const [items, setItems] = useState({
    activeObject: location.pathname,
    objects: headerIcons,
  });

  useEffect(() => {
    setItems({
      activeObject: location.pathname,
      objects: headerIcons,
    });
  }, [location.pathname]);

  const toggleActive = (index) => {
    setItems({
      ...items,
      activeObject: items.objects[index],
    });
    dispatch(setMenuVisibility(false));
    dispatch(setCartVisibility(false));
    dispatch(setLanguageVisibility(false));
    dispatch(setOptionsVisibility(false));
  };

  const toggleActiveStyles = (index, path, name) => {
    if (
      items.objects[index] === items.activeObject ||
      (path === items.activeObject &&
        size.width < 640 &&
        path === items.activeObject)
    ) {
      if (
        (name === "products" && !areMenusOpened.menu) ||
        (name === "cart" && !areMenusOpened.cart) ||
        (name === "language" && !areMenusOpened.language)
      ) {
        return "inactive";
      } else {
        if (name === "cart" && areMenusOpened.cart) {
          return "activeOffer";
        } else return "active";
      }
    } else {
      return "inactive";
    }
  };

  const dropdownHandler = (name) => {
    if (name === "products") {
      dispatch(setMenuVisibility(!areMenusOpened.menu));
    } else if (name === "cart") {
      dispatch(setCartVisibility(!areMenusOpened.cart));
    } else if (name === "language") {
      dispatch(setLanguageVisibility(!areMenusOpened.language));
    }
  };

  const offersCount = (elementNameIcon) => {
    if (elementNameIcon === "cart" && cart.cart.length > 0) {
      return (
        <m.div
          animate={{ scale: areMenusOpened.cart ? 0 : 1 }}
          transition={{
            duration: 0.4,
            delay: 0.1,
          }}
          className={classes.offersCountMobile}
        >
          <m.div
            animate={{ y: areMenusOpened.cart ? "130%" : 0 }}
            transition={{
              duration: 0.2,
              delay: 0.4,
              ease: "easeIn",
            }}
          >
            <p>{cart.cart.length}</p>
          </m.div>
        </m.div>
      );
    }
  };

  const iconsStyle = (
    elementId,
    elementMobileFillIcon,
    elementIcon,
    elementNameIcon,
    elementPath,
    elementStyle
  ) => {
    if (
      items.objects[elementId] === items.activeObject ||
      elementPath === items.activeObject
    ) {
      if (
        (elementNameIcon === "products" && !areMenusOpened.menu) ||
        (elementNameIcon === "cart" && !areMenusOpened.cart) ||
        (elementNameIcon === "language" && !areMenusOpened.language)
      ) {
        setItems({ ...items, activeObject: location.pathname });
        return <span>{elementIcon}</span>;
      }
      return (
        <>
          <span className={classes.iconWrapper}>
            <span className={classes[elementStyle]}>
              {elementMobileFillIcon}
            </span>
            {offersCount(elementNameIcon)}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={classes.iconWrapper}>
            {elementIcon}
            {offersCount(elementNameIcon)}
          </span>
        </>
      );
    }
  };

  const setActiveId = (index) => {
    setActiveAndPrev({
      prev: activeAndPrev.active,
      active: index,
    });
  };

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      {items.objects.map((elements, i) => (
        <m.li
          key={i}
          {...hideLisOnSearch({ isDesktopSearchActive, size })}
          transition={{
            duration: 0.3,
            delay: isDesktopSearchActive ? 0.2 : 0.4,
          }}
          onClick={() => {
            setActiveId(i);
            toggleActive(i);
            dropdownHandler(elements.nameIcon);
          }}
          className={
            classes[toggleActiveStyles(i, elements.path, elements.nameIcon)]
          }
        >
          <Link
            to={elements.type === "menu" ? elements.path : location.pathname}
          >
            {iconsStyle(
              i,
              elements.mobileFillIcon,
              elements.icon,
              elements.nameIcon,
              elements.path,
              elements.style
            )}
            <span className={classes.list}>{t(`links.${elements.nameIcon}`)}</span>
          </Link>
        </m.li>
      ))}
    </IconContext.Provider>
  );
};

export default NavigationMenu;
