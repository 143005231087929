//Hooks
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch } from "react-redux";
import { setSearchResults, clearInputValue } from "../../../../store/index";
import { useTranslation } from "react-i18next";

//CSS
import classes from "./TopSearchSuggestionsSwiper.module.css";

const TopSearchSuggestionsSwiper = ({ data, setSearch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubMenus = (arr) => {
    const arrayOfProducts = arr.subMenu.flatMap((item) => item.searchQueryPath);
    dispatch(setSearchResults([]));
    dispatch(setSearchResults(arrayOfProducts));
    dispatch(clearInputValue());
    setSearch(false);
  };

  return (
    <Swiper spaceBetween={0} slidesPerView="auto" className={classes.menu}>
      {data.map((item, i) => (
        <SwiperSlide
          className={classes.menuSlides}
          key={i}
          onClick={() => handleSubMenus(item)}
        >
          <p>{t("pages."+ item.name)}</p>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TopSearchSuggestionsSwiper;
