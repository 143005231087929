//Hooks
import { useTranslation } from "react-i18next";

//CSS
import classes from "./BoxSize.module.css";

const BoxSizes = ({ setBoxSize, boxSize, orderData }) => {
  const { t } = useTranslation();

  const handleSelectSize = (size) => {
    setBoxSize({ pieces: size.pieces, price: size.price });
  };

  const handleSizeStyle = (item) => {
    return {
      backgroundColor: item.pieces === boxSize.pieces ? "var(--brown)" : null,
      color: item.pieces === boxSize.pieces ? "var(--sandWhite)" : null,
    };
  };

  const sizeText = orderData.sizes.length < 2 ? "box size" : "select box size";

  return (
    <div className={classes.container}>
      <h6>{t("other." + sizeText)}:</h6>
      <ol className={classes.wrapper}>
        {orderData.sizes.map((item, i) => (
          <li
            key={i}
            onClick={() => handleSelectSize(item)}
            style={handleSizeStyle(item)}
          >
            <span>{item.pieces + " " + t("other.pieces")}</span>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default BoxSizes;
